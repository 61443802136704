import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { ReactComponent as AccountIcon } from '../../../../assets/account_icon.svg';
import RoundButton from '../../RoundButton/RoundButton';
import { logout } from '../../../../actions/authActions';
import './UserMenu.scss';

const HeaderUserMenu = ({ logout }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  return (
    <div className='um__container' data-testid='user-menu'>
      <RoundButton logo={<AccountIcon />} onClick={toggleMenu} />
      {isMenuOpen && (
        <Fragment>
          <div className='um'>
            <ul className='um__list'>
              <li className='um__list-item'>
                <NavLink to='/profile'>Профиль</NavLink>
              </li>
              <span className='um__list-divider'></span>
              <li className='um__list-item'>
                <button onClick={logout}>Выход</button>
              </li>
            </ul>
          </div>
          <div className='um__background' onClick={toggleMenu}></div>
        </Fragment>
      )}
    </div>
  );
};

export default connect(null, { logout })(HeaderUserMenu);
