import { VIN, VIN_CHASSIS, MAKE, MODEL, COLOR } from '../config/eptsConstants';

const resolveEpts = (epts) => {

  const vehicleData = {
    make: '',
    model: '',
    color: '',
    vin: '',
    vinChassis: null,
  };

  if (!epts) {
      return vehicleData;
  }

  epts.elements.forEach((field) => {
    if (field.Element === VIN) {
      vehicleData.vin = field?.Value.replace(/[^0-9A-Z]/g, '') || '';
    }
    if (field.Element === VIN_CHASSIS) {
      vehicleData.vinChassis = field?.Value === 'Отсутствует' || !field?.Value ? '' : field.Value.replace(/[^0-9A-Z]/g, '');
    }
    if (field.Element === COLOR) {
      vehicleData.color = field?.Value || '';
    }
    if (field.Element === MAKE) {
      vehicleData.make = field?.Value === 'Отсутствует' ? '' : field.Value;
    }
    if (field.Element === MODEL) {
      vehicleData.model = field?.Value === 'Отсутствует' ? '' : field.Value;
    }
  });

  return vehicleData;
};

export default resolveEpts;
