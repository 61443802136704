import React from 'react';
import { connect } from 'react-redux';
import Button from '../Button/Button';
import { removeAlert } from '../../../actions/alertActions';
import './Alert.scss';

const Alert = ({ alerts, removeAlert }) => {
  return (
    alerts.length > 0 &&
    alerts.map((alert) => (
      <div key={alert.id} className='alert__background'>
        <div className='alert'>
          {alert.type === 'danger' && (
            <h2 className='alert__header alert__header--danger'>Ошибка</h2>
          )}
          {alert.type === 'success' && (
            <h2 className='alert__header alert__header--success'>Выполнено</h2>
          )}
          <p className='alert__message'>{alert.msg}</p>
          <Button label='ОК' onClick={() => removeAlert(alert.id)} />
        </div>
      </div>
    ))
  );
};

const mapStateToProps = (state) => ({
  alerts: state.alerts,
});

export default connect(mapStateToProps, { removeAlert })(Alert);
