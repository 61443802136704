import {
  LESSOR_ORDER_REQ_START,
  LESSOR_ORDER_REQ_END,
  LESSOR_ORDER_GET_SUCCESS,
  LESSOR_ORDER_GET_FAIL,
  LESSOR_ORDER_ADD_FAIL,
  LESSOR_ORDER_ADD_SUCCESS,
  LESSOR_ORDER_CLEAR_ERRORS,
  LESSOR_ORDER_CLEAR_NOTIFICATIONS,
  LESSOR_ORDER_DETAILS_GET_SUCCESS,
  LESSOR_ORDER_DETAILS_GET_FAIL,
  LESSOR_ORDER_DETAILS_CLEAR,
} from '../actions/types';

const initialState = {
  orders: null,
  loading: false,
  notifications: null,
  errors: null,
  orderDetails: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case LESSOR_ORDER_GET_SUCCESS:
      return {
        ...state,
        orders: action.payload,
      };
    case LESSOR_ORDER_DETAILS_GET_SUCCESS:
      return {
        ...state,
        orderDetails: action.payload,
      };
    case LESSOR_ORDER_GET_FAIL:
      return {
        ...state,
        orders: null,
      };
    case LESSOR_ORDER_DETAILS_GET_FAIL:
      return {
        ...state,
        errors: action.payload,
        orderDetails: null,
      };
    case LESSOR_ORDER_ADD_SUCCESS:
      return {
        ...state,
        notifications: action.payload,
      };
    case LESSOR_ORDER_CLEAR_NOTIFICATIONS:
      return {
        ...state,
        notifications: null,
      };
    case LESSOR_ORDER_ADD_FAIL:
      return {
        ...state,
        errors: action.payload,
      };
    case LESSOR_ORDER_CLEAR_ERRORS:
      return {
        ...state,
        errors: null,
      };
    case LESSOR_ORDER_REQ_START:
      return {
        ...state,
        loading: true,
      };
    case LESSOR_ORDER_REQ_END:
      return {
        ...state,
        loading: false,
      };
    case LESSOR_ORDER_DETAILS_CLEAR:
      return {
        ...state,
        orderDetails: null,
      };
    default:
      return state;
  }
};
