import axios from 'axios';
import {
  UNDERWRITER_ORDER_ADD_SUCCESS,
  UNDERWRITER_ORDER_ADD_FAIL,
  UNDERWRITER_ORDER_REQ_START,
  UNDERWRITER_ORDER_REQ_END,
  UNDERWRITER_ORDER_CLEAR_ERRORS,
  UNDERWRITER_ORDER_CLEAR_NOTIFICATIONS,
  UNDERWRITER_ORDER_GET_SUCCESS,
  UNDERWRITER_ORDER_GET_FAIL,
  UNDERWRITER_ORDER_DETAILS_GET_SUCCESS,
  UNDERWRITER_ORDER_DETAILS_GET_FAIL,
  UNDERWRITER_ORDER_DETAILS_CLEAR,
} from '../actions/types';

const url = process.env.REACT_APP_API_URL;

export const addUnderwriterOrder = (data) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    dispatch({ type: UNDERWRITER_ORDER_REQ_START });

    const res = await axios.post(`${url}/api/v2/underwriter`, data, config);

    dispatch({ type: UNDERWRITER_ORDER_ADD_SUCCESS, payload: res.data.data });
  } catch (err) {
    dispatch({
      type: UNDERWRITER_ORDER_ADD_FAIL,
      payload: err.response.data.error,
    });
  }
  dispatch({ type: UNDERWRITER_ORDER_REQ_END });
};

export const getOrders = (options) => async (dispatch) => {
  let params = '?query=';
  if (options && options.query.length > 0) {
    params = `?query=${options.query}`;
  }
  params += `&startDate=${options.from}&endDate=${options.upTo}`;

  try {
    dispatch({ type: UNDERWRITER_ORDER_REQ_START });
    const res = await axios.get(`${url}/api/v2/underwriter/${params}`);

    dispatch({ type: UNDERWRITER_ORDER_GET_SUCCESS, payload: res.data.data });
  } catch (err) {
    dispatch({
      type: UNDERWRITER_ORDER_GET_FAIL,
      payload: err.response.data.error,
    });
  }
  dispatch({ type: UNDERWRITER_ORDER_REQ_END });
};

export const getOrderDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: UNDERWRITER_ORDER_REQ_START });
    const res = await axios.get(`${url}/api/v2/underwriter/${id}`);
    dispatch({
      type: UNDERWRITER_ORDER_DETAILS_GET_SUCCESS,
      payload: res.data.orderDetails,
    });
  } catch (err) {
    dispatch({
      type: UNDERWRITER_ORDER_DETAILS_GET_FAIL,
      payload: err.response.data.error,
    });
  }
  dispatch({ type: UNDERWRITER_ORDER_REQ_END });
};

export const clearOrderDetails = () => async (dispatch) =>
  dispatch({ type: UNDERWRITER_ORDER_DETAILS_CLEAR });

export const clearUnderwriterOrderErrors = () => async (dispatch) => {
  dispatch({ type: UNDERWRITER_ORDER_CLEAR_ERRORS });
};

export const clearUnderwriterOrderNotifications = () => async (dispatch) => {
  dispatch({ type: UNDERWRITER_ORDER_CLEAR_NOTIFICATIONS });
};
