import React, { useState, useEffect } from 'react';
import SearchRow from '../../../../layouts/SearchRow/SearchRow';
import DatePicker from '../../../../layouts/datePicker/DatePicker';
import moment from 'moment';
import { connect } from 'react-redux';
import { getOrders } from '../../../../../actions/orderActions';
import './LessorStatisticSearchBar.scss';
import Button from "../../../../layouts/Button/Button";

const LessorStatisticSearchBar = ({ getOrders }) => {
  const [options, setOptions] = useState({ query: '', from: '', upTo: '' });
  // eslint-disable-next-line
  const [selectedMonth, setSelectedMonth] = useState(moment().month());
  const months = {
    0: 'Январь',
    1: 'Февраль',
    2: 'Март',
    3: 'Апрель',
    4: 'Май',
    5: 'Июнь',
    6: 'Июль',
    7: 'Август',
    8: 'Сентябрь',
    9: 'Октябрь',
    10: 'Ноябрь',
    11: 'Декабрь',
  };

  useEffect(() => {
    const month =  moment().month()
    const firstDateOfMonth = moment()
        .set('month', month).startOf('month')
        .format('DD.MM.YYYY');
    const lastDateOfMonth = moment()
        .set('month', month).endOf('month')
        .format('DD.MM.YYYY');

      getOrders({...options, from: firstDateOfMonth, upTo: lastDateOfMonth});
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    selectMonth(moment().month());
    // eslint-disable-next-line
  }, []);

  const selectMonth = (month) => {
    const firstDateOfMonth = moment()
      .set('month', month).startOf('month')
      .format('DD.MM.YYYY');
    const lastDateOfMonth = moment()
      .set('month', month).endOf('month')
      .format('DD.MM.YYYY');
    setOptions({
      ...options,
      from: firstDateOfMonth,
      upTo: lastDateOfMonth,
    });
  };

  const renderMonths = () => {
    const fromMonth = moment(options.from, 'DD.MM.YYYY').month();
    const upToMonth = moment(options.upTo, 'DD.MM.YYYY').month();
    let monthsElementArray = [];
    const currentMonth = moment().month();
    for (let i = 0; i < currentMonth + 1; i++) {
      monthsElementArray.push(
        <button
          className={`search-bar__month search-bar__month--${
            fromMonth === i && upToMonth === i ? 'selected' : ''
          }`}
          key={i}
          onClick={() => {
            selectMonth(i);
            setSelectedMonth(i);
          }}>
          {months[i]}
        </button>
      );
    }
    return monthsElementArray;
  };

  const searchOrders = (query) => {
    setOptions({
      ...options,
      query,
    });
  };

  const search = () => {
    if (options.from !== '' && options.upTo !== '') {
      getOrders(options);
    }
  }

  return (
    <div className='search-bar'>
      <div className='search-bar__filters'>
        <SearchRow requestSearch={searchOrders} />
        <div className='search-bar__search-date'>
          <span>от:</span>
          <DatePicker
            title={'от'}
            value={options.from}
            name='from'
            timePicker={false}
            onChange={(from) => {
              if (from !== 'Invalid date') {
                setOptions({
                  ...options,
                  from,
                });
              }
            }}
          />
        </div>
        <div className='search-bar__search-date'>
          <span>до:</span>
          <DatePicker
            title={'до'}
            value={options.upTo}
            name='upTo'
            timePicker={false}
            onChange={(upTo) => {
              if (upTo !== 'Invalid date') {
                setOptions({
                  ...options,
                  upTo,
                });
              }
            }}
          />
        </div>
        <div className='search-bar__search-button'>
         <Button label={'Поиск'} onClick={search}/>
        </div>
      </div>
      <div className='search-bar__months'>{renderMonths()}</div>
    </div>
  );
};

export default connect(null, { getOrders })(LessorStatisticSearchBar);
