import React from 'react';
import './FlatButton.scss';

const FlatButton = ({ danger = false, label = '', onClick = null }) => {
  const onButtonClick = (e) => {
    e.preventDefault();
    if (onClick) {
      onClick();
    }
  };
  return (
    <button
      onClick={onButtonClick}
      className={`flat-button flat-button--${danger && 'danger'}`}
    >
      {label}
    </button>
  );
};

export default FlatButton;
