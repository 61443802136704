import React from 'react';
import './LessorStatisticSummary.scss';

const LessorStatisticSummary = ({
  total = 0,
  done = 0,
  canceled = 0,
  preparation = 0,
}) => {
  return (
    <div className='ls-summary'>
      <div className='ls-summary__total'>
        <p className='ls-summary__name'>Всего заявок</p>
        <p className='ls-summary__count'>{total}</p>
      </div>
      <div className='ls-summary__details'>
        <p className='ls-summary__name'>В обработке</p>
        <p className='ls-summary__count'>{preparation}</p>
        <p className='ls-summary__name'>Выполнено</p>
        <p className='ls-summary__count ls-summary__count--done'>{done}</p>
        <p className='ls-summary__name'>Отменено</p>
        <p className='ls-summary__count ls-summary__count--canceled'>
          {canceled}
        </p>
      </div>
    </div>
  );
};

export default LessorStatisticSummary;
