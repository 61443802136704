import React, { useState, useEffect } from 'react';
import './login.scss';
import { ReactComponent as Logo } from '../../../assets/labyrinth_logo.svg';
import { ReactComponent as StyleElement } from '../../../assets/style-element.svg';
import { ReactComponent as ClearIcon } from '../../../assets/clear_icon.svg';
import { ReactComponent as ShowPswdIcon } from '../../../assets/eye_icon.svg';
import ForgotPassword from '../../layouts/forgotPassword/ForgotPassword';
import { connect } from 'react-redux';
import {
  login,
  loadUser,
  rememberMe,
  clearErrors,
} from '../../../actions/authActions';
import CheckBox from '../../layouts/CheckBox/CheckBox';
import { setAlert } from '../../../actions/alertActions';

const Login = ({
  login,
  loadUser,
  rememberMe,
  clearErrors,
  auth: { token, user, error },
  history,
  setAlert,
}) => {
  const initCredentialsState = {
    login: '',
    password: '',
  };
  const [isModalOpen, setModalOpen] = useState(false);
  const [credentials, setCredentials] = useState(initCredentialsState);
  const [rememberMeFlag, setRememberMeFlag] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (user) {
      if (user.role === 'headOfLeasing' || user.role === 'leasingManager') {
        history.push(`/leasing-dashboard/`);
      } else {
        history.push('/insurance-agent-dashboard/');
      }
    }
    // eslint-disable-next-line
  }, [user]);

  /**
   * Save rememberMeFlag true on reload page when there is token
   * Set rememberMeFlag to false when there is no token
   */
  useEffect(() => {
    if (token) {
      loadUser();
    } else {
      rememberMe(rememberMeFlag);
    }
    // eslint-disable-next-line
  }, [token, rememberMeFlag]);

  useEffect(() => {
    if (error && error === 'Invalid credentials') {
      setAlert('Неверно введен логин или пароль.', 'danger');
      clearErrors();
      setCredentials(initCredentialsState);
    }
    if (error && error === 'Please provide an login and password') {
      setAlert('Введите логин и пароль для авторизации.', 'danger');
      clearErrors();
      setCredentials(initCredentialsState);
    }
    // eslint-disable-next-line
  }, [error]);

  const onChange = (e) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };

  const togglePassword = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };

  const onNumberInput = (e) => {
    e.target.value = e.target.value.replace(/\D/g, '');
    onChange(e);
  };

  const clearLogin = (e) => {
    e.preventDefault();
    setCredentials({
      ...credentials,
      login: '',
    });
  };

  const forgotPassword = (e) => {
    e.preventDefault();
    setModalOpen(true);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    login(credentials);
  };

  return (
    <div className='login-page'>
      <div className='login-page__logo-side'>
        <StyleElement className='login-page__style-element' />
        <div className='login-page__logo-container'>
          <Logo className='login-page__logo' />
        </div>
      </div>
      <div className='login-page__form-side'>
        <form onSubmit={onSubmit} className='login-page__form'>
          <h2 className='login-page__form-title'>Вход</h2>
          <div className='login-page__login'>
            <input
              className='login-page__input'
              type='text'
              name='login'
              value={credentials.login}
              onChange={onNumberInput}
              maxLength='5'
              placeholder='Логин'
            />
            <button className='login-page__clear' onClick={clearLogin}>
              <ClearIcon />
            </button>
          </div>
          <div className='login-page__login'>
            {showPassword ? (
              <input
                className='login-page__input'
                type='text'
                name='password'
                value={credentials.password}
                onChange={onChange}
                placeholder='Пароль'
              />
            ) : (
              <input
                className='login-page__input'
                type='password'
                name='password'
                value={credentials.password}
                onChange={onChange}
                placeholder='Пароль'
              />
            )}
            <button className='login-page__clear' onClick={togglePassword}>
              <ShowPswdIcon />
            </button>
          </div>
          <input
            className='login-page__submit-btn'
            type='submit'
            value='Войти'
          />
        </form>
        <div className='login-page__pswd-actions'>
          <CheckBox
            name='rememberMe'
            id='rememberMe'
            checked={rememberMeFlag}
            label='Запомнить меня'
            onChange={() => setRememberMeFlag(!rememberMeFlag)}
            dark
          />
          <button className='login-page__forgot-pswd' onClick={forgotPassword}>
            Забыли пароль?
          </button>
        </div>
      </div>
      {isModalOpen && <ForgotPassword setModalOpen={setModalOpen} />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  login,
  loadUser,
  rememberMe,
  clearErrors,
  setAlert,
})(Login);
