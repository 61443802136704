import React, { useState, useMemo } from 'react';
import UnderwriterStatisticTableRow from './UnderwriterStatisticTableRow';
import { ReactComponent as SortIcon } from '../../../../../assets/sort_icon.svg';
import { ReactComponent as AscSortIcon } from '../../../../../assets/sort-asc_icon.svg';
import { ReactComponent as DescSortIcon } from '../../../../../assets/sort-desc_icon.svg';

const UnderwriterStatisticTable = ({ tableData, onClickRow }) => {
  const [sortedField, setSortedField] = useState(null);
  const [isAsc, setIsAsc] = useState(true);

  const sortedTableData = useMemo(() => {
    let sortableTableData = [...tableData];
    if (sortedField) {
      sortableTableData.sort((a, b) => {
        if (a[`${sortedField}`] < b[`${sortedField}`]) {
          return isAsc ? -1 : 1;
        }
        if (a[`${sortedField}`] > b[`${sortedField}`]) {
          return isAsc ? 1 : -1;
        }
        return 0;
      });
    }
    return sortableTableData;
  }, [tableData, sortedField, isAsc]);

  const sortFields = (field) => {
    if (field === sortedField) {
      setIsAsc(!isAsc);
    } else {
      setIsAsc(true);
      setSortedField(field);
    }
  };

  return (
    <div className='statistic-table__container'>
      <table className='statistic-table'>
        <thead className='statistic-table__head'>
          <tr className='statistic-table__row'>
            <th className='statistic-table__header'>
              <button
                className='statistic-table__sortable-header'
                onClick={() => sortFields('orderType')}>
                <span>Тип работ</span>
                <span>
                  {isAsc && sortedField === 'orderType' && <AscSortIcon />}
                  {!isAsc && sortedField === 'orderType' && <DescSortIcon />}
                  {sortedField !== 'orderType' && <SortIcon />}
                </span>
              </button>
            </th>
            <th className='statistic-table__header'>ФИО владельца</th>
            <th className='statistic-table__header'>Марка/Модель</th>
            <th className='statistic-table__header'>
              <button
                className='statistic-table__sortable-header'
                onClick={() => sortFields('closedAt')}>
                <span>Дата выполнения заявки</span>
                <span>
                  {isAsc && sortedField === 'closedAt' && <AscSortIcon />}
                  {!isAsc && sortedField === 'closedAt' && <DescSortIcon />}
                  {sortedField !== 'closedAt' && <SortIcon />}
                </span>
              </button>
            </th>
            <th className='statistic-table__header'>
              <button
                className='statistic-table__sortable-header'
                onClick={() => sortFields('status')}>
                <span>Статус</span>
                <span>
                  {isAsc && sortedField === 'status' && <AscSortIcon />}
                  {!isAsc && sortedField === 'status' && <DescSortIcon />}
                  {sortedField !== 'status' && <SortIcon />}
                </span>
              </button>
            </th>
          </tr>
        </thead>
        <tbody className='statistic-table__body'>
          {sortedTableData.map((item) => (
            <UnderwriterStatisticTableRow
              key={item.id}
              data={item}
              onClickRow={onClickRow}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UnderwriterStatisticTable;
