import * as uuid from 'uuid';
import { SET_ALERT, REMOVE_ALERT } from './types';

export const setAlert = (msg, type) => async (dispatch) => {
  const id = uuid.v4();
  dispatch({
    type: SET_ALERT,
    payload: { msg, type, id },
  });
};

export const removeAlert = (id) => async (dispatch) => {
  dispatch({ type: REMOVE_ALERT, payload: id });
};
