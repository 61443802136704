import React, { Fragment } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import Drawer from '../../layouts/Drawer/Drawer/Drawer';
import DrawerContainer from '../../layouts/Drawer/DrawerContainer/DrawerContainer';
import DrawerNavLink from '../../layouts/Drawer/DrawerNavLink/DrawerNavLink';
import Header from '../../layouts/Header/Header';
import AuthorizedRout from '../../routing/AuthorizedRoute';
import UserData from './UserData/UserData';
import UserSecurity from './UserSecurity/UserSecurity';
import { ReactComponent as ProfileIcon } from '../../../assets/profile_icon.svg';
import { ReactComponent as ArrowIcon } from '../../../assets/arrow_icon.svg';
import { ReactComponent as SecurityIcon } from '../../../assets/security_icon.svg';
import { connect } from 'react-redux';
import './UserProfile.scss';

const UserProfile = ({ history, auth: { user } }) => {
  const backLink = (
    <button
      className='return-button'
      onClick={() => {
        if (user) {
          if (user.role === 'headOfLeasing' || user.role === 'leasingManager') {
            history.push(`/leasing-dashboard/`);
          } else {
            history.push('/insurance-agent-dashboard/');
          }
        }
      }}>
      <span className='return-button__icon'>
        <ArrowIcon />
      </span>
      <span className='return-button__label'>Платформа</span>
    </button>
  );
  return (
    <div>
      <Fragment>
        <Header />
        <DrawerContainer>
          <Drawer title={backLink}>
            <DrawerNavLink
              to='/profile/user-data/'
              name={'Профиль'}
              icon={ProfileIcon}
            />
            <DrawerNavLink
              to='/profile/security/'
              name={'Безопасность'}
              icon={SecurityIcon}
            />
          </Drawer>
          <div className='content-container'>
            <Switch>
              <AuthorizedRout path='/profile/user-data' component={UserData} />
              <AuthorizedRout
                path='/profile/security'
                component={UserSecurity}
              />
              <Redirect from='/profile' to='/profile/user-data/' />
            </Switch>
          </div>
        </DrawerContainer>
      </Fragment>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(UserProfile);
