import React, { useState } from 'react';
import { ReactComponent as ClearIcon } from '../../../assets/clear_icon.svg';
import Calendar from './calendar/Calendar';
import moment from 'moment';
import './datePicker.scss';

const DatePicker = (props) => {
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const onChangeDate = (newDate) => {
    let formattedDate = '';
    if (props.timePicker) {
      formattedDate = moment(newDate, 'DD.MM.YYYY HH:mm').format(
        'DD.MM.YYYY HH:mm'
      );
    } else {
      formattedDate = moment(newDate, 'DD.MM.YYYY').format('DD.MM.YYYY');
    }
    props.onChange(formattedDate);
  };

  return (
    <div className='date-picker__container'>
      <div className={`date-picker date-picker--${props.error && 'error'}`}>
        <input
          name={props.name}
          type='text'
          className='date-picker__input'
          placeholder={props.placeholder}
          value={props.value}
          ref={props.register}
          readOnly
          onClick={(e) => {
            e.preventDefault();
            setIsCalendarOpen(!isCalendarOpen);
          }}
        />
        <Calendar
          onChangeDate={onChangeDate}
          setIsCalendarOpen={setIsCalendarOpen}
          isOpen={isCalendarOpen}
          value={props.value}
          startDate={props.startDate}
          endDate={props.endDate}
          isPortal={props.isPortal}
          timePicker={props.timePicker}
          top={props.top}
        />
        {false && (
          <button className='date-picker__clear-btn'>
            <ClearIcon />
          </button>
        )}
      </div>
      {props.error && props.error.message && (
        <small className='date-picker__error'>{props.error.message}</small>
      )}
    </div>
  );
};

export default DatePicker;
