import React from 'react';
import TextField from '../../../layouts/TextField/TextField';
import { useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import Button from '../../../layouts/Button/Button';
import { updateContactDetails } from '../../../../actions/authActions';
import PhoneInput from '../../../layouts/PhoneInput/PhoneInput';

const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const UpdateContactDetails = ({
  setEditMode,
  updateContactDetails,
  setNewContactDetails,
  email,
  phone,
}) => {
  const { handleSubmit, watch, setValue, errors, register } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: email,
      phone: phone,
    },
  });

  const closeEditMode = () => {
    setEditMode(false);
  };

  const submitForm = (data) => {
    updateContactDetails(data.email, data.phone);
    setNewContactDetails({ email: data.email, phone: data.phone });
  };

  return (
    <form onSubmit={handleSubmit(submitForm)}>
      <div className='user-data__row'>
        <p className='user-data__label'>Email</p>
        <div className='user-data__value'>
          <TextField
            placeholder='email'
            register={register({
              required: 'Введите адрес электронной почты.',
              pattern: {
                value: emailPattern,
                message: 'Неверный формат адреса электронной почты.',
              },
            })}
            name='email'
            setValue={setValue}
            error={errors.email}
            value={watch('email')}
          />
        </div>
      </div>
      <div className='user-data__row'>
        <p className='user-data__label'>Номер телефона</p>
        <div className='user-data__value'>
          <PhoneInput
            register={register({
              required: 'Введите номер телефона.',
              pattern: {
                value: /^\+[7]\s\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/,
                message: 'Введите номер телефона.',
              },
            })}
            name='phone'
            setValue={setValue}
            error={errors.phone}
            value={watch('phone')}
          />
        </div>
      </div>
      <div className='user-data__row'>
        <Button type={'submit'} label={'Сохранить изменения'} />
        <Button label={'Отменить'} onClick={closeEditMode} />
      </div>
    </form>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  updateContactDetails,
})(UpdateContactDetails);
