import React from 'react';
import { ScaleLoader } from 'react-spinners';
import './modalLoading.scss';

const ModalLoading = () => {
  return (
    <div className='modal-loading'>
      <ScaleLoader
        color='#00a86e'
        height='48px'
        width='6px'
        radius='5px'
        margin='4px'
      />
      <span>Загрузка...</span>
    </div>
  );
};

export default ModalLoading;
