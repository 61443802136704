import React, { useState, useEffect, useRef, Fragment } from 'react';
import { ReactComponent as ArrowLeft } from '../../../../assets/arrow_icon.svg';
import { ReactComponent as ArrowRight } from '../../../../assets/arrow_icon.svg';
import moment from 'moment';
import './calendar.scss';

const Calendar = ({
  onChangeDate,
  isOpen,
  setIsCalendarOpen,
  value,
  startDate = null,
  endDate = null,
  isPortal,
  timePicker = false,
  top = false,
}) => {
  const [currentMonth, setCurrentMonth] = useState();
  const [currentYear, setCurrentYear] = useState();
  // eslint-disable-next-line
  const [currentDate, setCurrentDate] = useState();
  const [daysInCurrentMonth, setDaysInCurrentMonth] = useState(0);
  const [firstDayOfMonth, setFirstDayOfMonth] = useState(0);
  const [selectedDate, setSelectedDate] = useState();
  const [selectedTime, setSelectedTime] = useState();
  const months = {
    1: 'Январь',
    2: 'Февраль',
    3: 'Март',
    4: 'Апрель',
    5: 'Май',
    6: 'Июнь',
    7: 'Июль',
    8: 'Август',
    9: 'Сентябрь',
    10: 'Октябрь',
    11: 'Ноябрь',
    12: 'Декабрь',
  };
  const calendar = useRef(null);
  const timePickerContainer = useRef(null);

  const [swipingDates, setSwipingDates] = useState(true);

  useEffect(() => {
    if (!swipingDates) {
      setSwipingDates(true);
    }
  }, [swipingDates]);

  useEffect(() => {
    let currentDate = parseInt(moment(value, 'DD.MM.YYYY HH:mm').format('D'));
    let currentMonth = parseInt(moment(value, 'DD.MM.YYYY HH:mm').format('M'));
    let currentYear = parseInt(
      moment(value, 'DD.MM.YYYY HH:mm').format('YYYY')
    );
    let currentTime = moment(value, 'DD.MM.YYYY HH:mm').format('HH:mm');
    setCurrentDate(currentDate);
    setCurrentMonth(currentMonth);
    setCurrentYear(currentYear);
    setSelectedDate(`${currentDate}.${currentMonth}.${currentYear}`);
    setSelectedTime(currentTime);
  }, [value]);

  useEffect(() => {
    const current = calendar.current;
    document.addEventListener('mousedown', (e) => {
      if (current && !current.contains(e.target)) {
        setIsCalendarOpen(false);
      }
    });
    return () => {
      document.addEventListener('mousedown', (e) => {
        if (current && !current.contains(e.target)) {
          setIsCalendarOpen(false);
        }
      });
    };
    // eslint-disable-next-line
  }, [calendar]);

  useEffect(() => {
    setDaysInCurrentMonth(getDaysInMonth(currentMonth, currentYear));
    setFirstDayOfMonth(getFirstDayOfMonth(currentMonth, currentYear));
    // eslint-disable-next-line
  }, [currentMonth]);

  useEffect(() => {
    onChangeDate(`${selectedDate} ${selectedTime}`);
    // eslint-disable-next-line
  }, [selectedDate, selectedTime]);

  const getDaysInMonth = (month, year) => {
    return moment(`${year}-${month}`, 'YYYY-M').daysInMonth();
  };

  const getFirstDayOfMonth = (month, year) => {
    return moment(`${year}-${month}`, 'YYYY-M').day();
  };

  const onNextMonth = (e) => {
    e.preventDefault();
    setSwipingDates(false);
    if (currentMonth === 12) {
      setCurrentMonth(1);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  const onPrevMonth = (e) => {
    e.preventDefault();
    setSwipingDates(false);
    if (currentMonth === 1) {
      setCurrentMonth(12);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  const renderSkippedDays = (firstDayOfMonth) => {
    let skippedDays = [];
    let daysToSkip = 0;
    if (firstDayOfMonth === 0) {
      daysToSkip = 6;
    } else {
      daysToSkip = firstDayOfMonth - 1;
    }
    for (let i = 0; i < daysToSkip; i++) {
      skippedDays.push(
        <button
          key={i}
          className='calendar__day calendar__day--skipped'
          disabled
        ></button>
      );
    }
    return skippedDays;
  };

  const selectDate = (i) => {
    setCurrentDate(i);
    setSelectedDate(`${i}.${currentMonth}.${currentYear}`);
  };

  const renderDays = (daysInMonth) => {
    let days = [];
    for (let i = 0; i < daysInMonth; i++) {
      days.push(
        <button
          className={`calendar__day ${
            selectedDate === `${i + 1}.${currentMonth}.${currentYear}`
              ? 'calendar__day--selected'
              : ''
          }`}
          disabled={checkIfDayInRange(
            `${i + 1}.${currentMonth}.${currentYear}`
          )}
          onClick={(e) => {
            e.preventDefault();
            selectDate(i + 1);
            !timePicker && setIsCalendarOpen(false);
          }}
          key={i + 1}
        >
          {i + 1}
        </button>
      );
    }
    return days;
  };

  const checkIfDayInRange = (currentDate) => {
    if (
      moment(selectedDate, 'D.MM.YYYY').format('DD.MM.YYYY') ===
      moment(startDate, 'DD.MM.YYYY').format('DD.MM.YYYY')
    ) {
      if (moment(currentDate, 'D.MM.YYYY') >= moment(endDate, 'DD.MM.YYYY')) {
        return true;
      } else if (
        moment(currentDate, 'D.MM.YYYY') < moment().subtract(1, 'days')
      ) {
        return true;
      } else {
        return false;
      }
    } else if (
      moment(selectedDate, 'D.MM.YYYY').format('DD.MM.YYYY') ===
      moment(endDate, 'DD.MM.YYYY').format('DD.MM.YYYY')
    ) {
      if (moment(currentDate, 'D.MM.YYYY') <= moment(startDate, 'DD.MM.YYYY')) {
        return true;
      } else {
        return false;
      }
    }
  };

  const renderTimeStamps = () => {
    let timeStampsAmountInDay = 48;
    let timeStamps = [];
    for (let i = 0; i < timeStampsAmountInDay; i++) {
      let time = moment([1970, 0, 1]).add(30 * i, 'minute');
      if (
        moment(time).format('HH:mm') === selectedTime &&
        timePicker &&
        timePicker.current
      ) {
        timePicker.current.scrollTo(0, i * 23.4);
      }
      timeStamps.push(
        <button
          key={i}
          className={`calendar__time-picker__time calendar__time-picker__time--${
            selectedTime === moment(time).format('HH:mm') ? 'selected' : ''
          } `}
          onClick={(e) => {
            e.preventDefault();
            setSelectedTime(moment(time).format('HH:mm'));
            setIsCalendarOpen(false);
          }}
        >
          {moment(time).format('HH:mm')}
        </button>
      );
    }
    return timeStamps;
  };

  return (
    <div
      className={`calendar calendar--${isOpen ? 'open' : 'close'} calendar--${
        timePicker ? 'with-time' : 'without-time'
      } calendar--${top ? 'top' : 'bottom'}`}
      ref={calendar}
    >
      <div className='calendar__date-board'>
        <div className='calendar__header'>
          <button
            className='calendar__header-btn calendar__header-btn--left'
            onClick={onPrevMonth}
          >
            <ArrowLeft />
          </button>
          {swipingDates && (
            <p className='calendar__current-month'>{`${months[currentMonth]} ${currentYear}`}</p>
          )}
          <button
            className='calendar__header-btn calendar__header-btn--right'
            onClick={onNextMonth}
          >
            <ArrowRight />
          </button>
        </div>
        <div className='calendar__days'>
          <span className='calendar__day-of-week'>Пн</span>
          <span className='calendar__day-of-week'>Вт</span>
          <span className='calendar__day-of-week'>Ср</span>
          <span className='calendar__day-of-week'>Чт</span>
          <span className='calendar__day-of-week'>Пт</span>
          <span className='calendar__day-of-week'>Сб</span>
          <span className='calendar__day-of-week'>Вс</span>
          {swipingDates && (
            <Fragment>
              {renderSkippedDays(firstDayOfMonth)}
              {renderDays(daysInCurrentMonth)}
            </Fragment>
          )}
        </div>
      </div>
      {timePicker && (
        <div className='calendar__time-picker' ref={timePickerContainer}>
          {renderTimeStamps()}
        </div>
      )}
    </div>
  );
};

export default Calendar;
