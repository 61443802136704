import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const AdminRoute = ({
  component: Component,
  auth: { user, isAuthenticated, loading, ...rest },
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (loading) {
          return '';
        }
        if (user && user.role === 'admin') {
          return <Component {...props} />;
        }
        if (!isAuthenticated && !loading) {
          return <Redirect to='/login' />;
        }
        return <Redirect to='/login' />;
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(AdminRoute);
