import axios from 'axios';
import {
  LESSOR_ORDER_GET_SUCCESS,
  LESSOR_ORDER_GET_FAIL,
  LESSOR_ORDER_REQ_START,
  LESSOR_ORDER_REQ_END,
  LESSOR_ORDER_ADD_SUCCESS,
  LESSOR_ORDER_ADD_FAIL,
  LESSOR_ORDER_CLEAR_ERRORS,
  LESSOR_ORDER_CLEAR_NOTIFICATIONS,
  LESSOR_ORDER_DETAILS_GET_SUCCESS,
  LESSOR_ORDER_DETAILS_GET_FAIL,
  LESSOR_ORDER_DETAILS_CLEAR,
} from '../actions/types';

const url = process.env.REACT_APP_API_URL;

export const addOrder = (data) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    dispatch({ type: LESSOR_ORDER_REQ_START });

    const res = await axios.post(`${url}/api/v2/order`, data, config);

    dispatch({ type: LESSOR_ORDER_ADD_SUCCESS, payload: res.data.data });
  } catch (err) {
    console.log(err);
    dispatch({
      type: LESSOR_ORDER_ADD_FAIL,
      payload: err?.response?.data?.error,
    });
  }
  dispatch({ type: LESSOR_ORDER_REQ_END });
};

export const getOrders = (options) => async (dispatch) => {
  let params = '?query=';
  if (options && options.query.length > 0) {
    params = `?query=${options.query}`;
  }
  params += `&startDate=${options.from}&endDate=${options.upTo}`;

  try {
    dispatch({ type: LESSOR_ORDER_REQ_START });
    const res = await axios.get(`${url}/api/v2/order/${params}`);

    dispatch({ type: LESSOR_ORDER_GET_SUCCESS, payload: res.data.data });
  } catch (err) {
    dispatch({ type: LESSOR_ORDER_GET_FAIL, payload: err.response.data.error });
  }
  dispatch({ type: LESSOR_ORDER_REQ_END });
};

export const getOrderDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: LESSOR_ORDER_REQ_START });
    const res = await axios.get(`${url}/api/v2/order/${id}`);
    dispatch({
      type: LESSOR_ORDER_DETAILS_GET_SUCCESS,
      payload: res.data.orderDetails,
    });
  } catch (err) {
    dispatch({
      type: LESSOR_ORDER_DETAILS_GET_FAIL,
      payload: err.response.data.error,
    });
  }
  dispatch({ type: LESSOR_ORDER_REQ_END });
};

export const clearOrderDetails = () => async (dispatch) =>
  dispatch({ type: LESSOR_ORDER_DETAILS_CLEAR });

export const clearLessorOrderErrors = () => async (dispatch) =>
  dispatch({ type: LESSOR_ORDER_CLEAR_ERRORS });

export const clearLessorOrderNotifications = () => async (dispatch) =>
  dispatch({ type: LESSOR_ORDER_CLEAR_NOTIFICATIONS });
