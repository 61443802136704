import React, { Fragment } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import UnderwriterRoute from '../../../routing/UnderwriterRoute';
import Header from '../../../layouts/Header/Header';
import Drawer from '../../../layouts/Drawer/Drawer/Drawer';
import DrawerContainer from '../../../layouts/Drawer/DrawerContainer/DrawerContainer';
import DrawerNavLink from '../../../layouts/Drawer/DrawerNavLink/DrawerNavLink';
import UnderwriterNewOrder from '../UnderwriterNewOrder/UnderwriterNewOrder';
import { ReactComponent as TeamIcon } from '../../../../assets/team_icon.svg';
import { ReactComponent as NewOrderIcon } from '../../../../assets/add-order_icon.svg';
import { ReactComponent as StatisticIcon } from '../../../../assets/statistic_icon.svg';
import { connect } from 'react-redux';
import './UnderwriterDashboard.scss';
import UnderwriterStatistic from '../UnderwriterStatistic/UnderwriterStatistic';
import UnderwriterTeam from '../UnderwriterTeam/UnderwriterTeam';

const UnderwriterDashboard = ({ auth: { user } }) => {
  return (
    <Fragment>
      <Header />
      <DrawerContainer>
        <Drawer title={user?.company?.companyName || 'Компания'}>
          <DrawerNavLink
            to='/insurance-agent-dashboard/new-order/'
            name={'Новая заявка'}
            icon={NewOrderIcon}
          />
          <DrawerNavLink
            to='/insurance-agent-dashboard/statistic/'
            name={'Статистика'}
            icon={StatisticIcon}
          />
          {user && (user.role === 'headOfInsurance' || user.role === 'admin') && (
            <DrawerNavLink
              to='/insurance-agent-dashboard/team/'
              name={'Команда'}
              icon={TeamIcon}
            />
          )}
        </Drawer>
        <div className='content-container'>
          <Switch>
            <UnderwriterRoute
              path='/insurance-agent-dashboard/new-order'
              component={UnderwriterNewOrder}
            />
            <UnderwriterRoute
              path='/insurance-agent-dashboard/statistic'
              component={UnderwriterStatistic}
            />
            <UnderwriterRoute
              path='/insurance-agent-dashboard/team'
              component={UnderwriterTeam}
            />
            <Redirect
              from='/insurance-agent-dashboard'
              to='/insurance-agent-dashboard/statistic'
            />
          </Switch>
        </div>
      </DrawerContainer>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(UnderwriterDashboard);
