import React, { Fragment } from 'react';
import { ReactComponent as CloseIcon } from '../../../../../assets/cross_icon.svg';
import './LessorTeamMember.scss';

const LessorTeamMemder = ({ teamMember, setMember }) => {
  const close = () => {
    setMember(null);
  };
  return (
    teamMember && (
      <Fragment>
        <div className='ltm__background' onClick={close}></div>
        <div className='ltm__container'>
          <div className='ltm__row'>
            <h2 className='ltm__header'>Пользователь</h2>
            <button className='ltm__close' onClick={close}>
              <CloseIcon />
            </button>
          </div>
          <div className='ltm__row'>
            <p className='ltm__section-name'>Персональные данные</p>
          </div>
          <div className='ltm__row'>
            <p className='ltm__label'>Полное имя</p>
            <p className='ltm__value'>
              {`${teamMember.lastName} ${teamMember.firstName} ${teamMember.patronymic}`}
            </p>
          </div>
          <div className='ltm__row'>
            <p className='ltm__label'>Логин</p>
            <p className='ltm__value'>{teamMember.login}</p>
          </div>
          <div className='ltm__row'>
            <p className='ltm__label'>Название компании</p>
            <p className='ltm__value'>{teamMember.companyName}</p>
          </div>
          <div className='ltm__row'>
            <p className='ltm__label'>Должность</p>
            <p className='ltm__value'>{teamMember.position}</p>
          </div>
          <div className='ltm__row'>
            <p className='ltm__label'>Регион</p>
            <p className='ltm__value'>{teamMember.region}</p>
          </div>
          <div className='ltm__row'>
            <p className='ltm__section-name'>Контактные данные</p>
          </div>
          <div className='ltm__row'>
            <p className='ltm__label'>Email</p>
            <p className='ltm__value'>{teamMember.email}</p>
          </div>
          <div className='ltm__row'>
            <p className='ltm__label'>Телефон</p>
            <p className='ltm__value'>{teamMember.phone}</p>
          </div>
        </div>
      </Fragment>
    )
  );
};

export default LessorTeamMemder;
