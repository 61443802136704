import React, { Fragment, useEffect } from 'react';
import moment from 'moment-timezone';
import { ReactComponent as CloseIcon } from '../../../../../assets/cross_icon.svg';
import './LessorStatisticModal.scss';
import declOfYear from '../../../../../utils/declOfYeaes';

const acceptedStatus = <p>Принята</p>;
const doneStatus = <p>Выполнена</p>;
const deniedStatus = <p>Отменена</p>;
const preparationStatus = <p>В обработке</p>;

const LessorStatisticModal = ({ data, onClose }) => {
  const statuses = {
    0: acceptedStatus,
    1: preparationStatus,
    2: doneStatus,
    3: deniedStatus,
  };
  useEffect(() => {}, []);
  return (
    data && (
      <Fragment>
        <div className='ls-modal__background' onClick={onClose}></div>
        <div className='ls-modal'>
          <div className='ls-modal__row'>
            <span className='us-modal__header-container'>
              <h2 className='us-modal__header'>Заявка</h2>
              {statuses[data.status]}
            </span>
            <button
              className='ls-modal__close'
              onClick={(e) => {
                e.preventDefault();
                onClose();
              }}
            >
              <CloseIcon />
            </button>
          </div>
          <div className='ls-modal__subject'>
            <div className='ls-modal__row'>
              <p className='ls-modal__section-name'>Предмет лизинга</p>
            </div>
            <div className='ls-modal__row'>
              <p className='ls-modal__label'>Лизингополучатель</p>
              <p className='ls-modal__value'>{data.leasingRecipient}</p>
            </div>
            <div className='ls-modal__row'>
              <p className='ls-modal__label'>Номер договора лизинга</p>
              <p className='ls-modal__value'>{data.leasingAgreement}</p>
            </div>
            <div className='ls-modal__row'>
              <p className='ls-modal__label'>Срок договора лизинга</p>
              <p className='ls-modal__value'> {declOfYear(data.leaseTerm)}</p>
            </div>
            <div className='ls-modal__row'>
              <p className='ls-modal__label'>Тип ТС</p>
              <p className='ls-modal__value'>
                {data.vinChassis && data.vinChassis.length > 0
                  ? 'спец.техника'
                  : 'общего пользования'}
              </p>
            </div>
            <div className='ls-modal__row'>
              <p className='ls-modal__label'>Марка/Модель</p>
              <p className='ls-modal__value'>{data.makeModel}</p>
            </div>
            <div className='ls-modal__row'>
              <p className='ls-modal__label'>Цвет</p>
              <p className='ls-modal__value'>{data.color}</p>
            </div>
            <div className='ls-modal__row'>
              <p className='ls-modal__label'>VIN</p>
              <p className='ls-modal__value'>{data.vin}</p>
            </div>
            {data.vinChassis && data.vinChassis.length > 0 && (
              <div className='ls-modal__row'>
                <p className='ls-modal__label'>Номер шасси</p>
                <p className='ls-modal__value'>{data.vinChassis}</p>
              </div>
            )}
          </div>
          <div className='ls-modal__installation-info'>
            <div className='ls-modal__row'>
              <p className='ls-modal__section-name'>Информация по установке</p>
            </div>
            <div className='ls-modal__row'>
              <p className='ls-modal__label'>ФИО/должность контактного лица</p>
              <p className='ls-modal__value'>{data.contact.name}</p>
            </div>
            <div className='ls-modal__row'>
              <p className='ls-modal__label'>Телефон</p>
              <p className='ls-modal__value'>
                {data?.contact?.phone.replace(
                  /([0-9]{1})([0-9]{1,3})([0-9]{1,3})([0-9]{1,4})/gi,
                  '$1 ($2) $3-$4'
                )}
              </p>
            </div>
            <div className='ls-modal__row'>
              <p className='ls-modal__label'>Адрес установки</p>
              <p className='ls-modal__value'>{data.installationAddress}</p>
            </div>
            <div className='ls-modal__row'>
              <p className='ls-modal__section-name'>Планируемая</p>
            </div>
            <div className='ls-modal__row'>
              <p className='ls-modal__label'>Дата и время начала установки</p>
              <p className='ls-modal__value'>
                {moment(data.installationDateFrom).format('DD.MM.YYYY HH:mm')}
              </p>
            </div>
            <div className='ls-modal__row'>
              <p className='ls-modal__label'>
                Дата и время окончания установки
              </p>
              <p className='ls-modal__value'>
                {moment(data.installationDateTo).format('DD.MM.YYYY HH:mm')}
              </p>
            </div>
            <div className='ls-modal__row'>
              <p className='ls-modal__label'>Дополнительная информация</p>
              <p className='ls-modal__value'>{data.comment || 'нет'}</p>
            </div>
            <div className='ls-modal__row'>
              <p className='ls-modal__section-name'>Фактическая</p>
            </div>
            <div className='ls-modal__row'>
              <p className='ls-modal__label'>Дата и время установки</p>
              <p className='ls-modal__value'>
                {data.closedAt
                  ? moment(data.closedAt).format('DD.MM.YYYY HH:mm')
                  : '-'}
              </p>
            </div>
            <div className='ls-modal__row'>
              <p className='ls-modal__label'>Информация по установке</p>
              <p className='ls-modal__value'>
                {data.installationInfo || 'нет'}
              </p>
            </div>
          </div>
          <div className='ls-modal__user'>
            <div className='ls-modal__row'>
              <p className='ls-modal__section-name'>Создал</p>
            </div>
            <div className='ls-modal__row'>
              <p className='ls-modal__label'>Логин</p>
              <p className='ls-modal__value'>{data.user.login}</p>
            </div>
            <div className='ls-modal__row'>
              <p className='ls-modal__label'>ФИО</p>
              <p className='ls-modal__value'>{data.user.name}</p>
            </div>
          </div>
        </div>
      </Fragment>
    )
  );
};

export default LessorStatisticModal;
