const declOfYear = (year) => {
    if (year === 1) {
        return `${year} год`
    }
    if (year > 1 && year < 5) {
        return `${year} года`
    }
    if (year === 5) {
        return `${year} лет`
    }
    return year;
};

export default declOfYear;
