import React from 'react';
import { ReactComponent as ClearIcon } from '../../../assets/clear_icon.svg';
import './TextField.scss';

const TextField = ({
  type = 'text',
  error = false,
  placeholder = '',
  name,
  register,
  setValue,
  textarea = false,
  value = '',
  maxLength = null,
  replaceChars = null,
  toUpperCase = false,
}) => {
  const clear = (e) => {
    e.preventDefault();
    if (setValue) {
      setValue(name, '', { shouldValidate: true });
    }
  };

  const onChange = (e) => {
    if (replaceChars) {
      // e.target.value = e.target.value.replace(replaceChars, '');
      let replacedString = e.target.value.replace(replaceChars, '');
      setValue(name, replacedString, { shouldValidate: true });
    }
    if (toUpperCase) {
      let upperCasedString = e.target.value.toUpperCase();
      // e.target.value = e.target.value.toUpperCase();
      setValue(name, upperCasedString, { shouldValidate: true });
    }
  };

  return (
    <div className='text-field__container'>
      <div className={`text-field text-field--${error && 'error'}`}>
        {textarea ? (
          <textarea
            name={name}
            className='text-field__textarea'
            ref={register}
            placeholder={placeholder}
            onChange={onChange}
            maxLength={maxLength}></textarea>
        ) : (
          <input
            name={name}
            type={type}
            className='text-field__input'
            placeholder={placeholder}
            ref={register}
            onChange={onChange}
            maxLength={maxLength}
          />
        )}
        {value.length > 0 && (
          <button className='text-field__clear-btn' onClick={clear}>
            <ClearIcon />
          </button>
        )}
      </div>
      {error && error.message && (
        <small className='text-field__error'>{error.message}</small>
      )}
    </div>
  );
};

export default TextField;
