import React from 'react';
import './checkBox.scss';

const CheckBox = ({
  name = 'checkbox',
  label = 'checkbox',
  onChange,
  checked,
  dark = false,
}) => {
  return (
    <label htmlFor={name} className={`check check--${dark ? 'dark' : ''}`}>
      <input
        type='checkbox'
        name={name}
        id={name}
        className='check__input'
        onChange={onChange}
        checked={checked}
      />
      <span className='check__box'></span>
      <span className='check__label'>{label}</span>
    </label>
  );
};

export default CheckBox;
