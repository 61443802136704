import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ReactComponent as NotificationIcon } from '../../../../assets/notification_icon.svg';
import RoundButton from '../../RoundButton/RoundButton';
import {
  getAllNotifications,
  deleteAllNotifications,
  deleteNotification,
} from '../../../../actions/notificationActions';
import './HeaderNotifications.scss';

const HeaderNotifications = ({
  notifications: { notifications },
  getAllNotifications,
  deleteAllNotifications,
  deleteNotification,
}) => {
  const [isNotificaionsOpen, setIsNotificaionsOpen] = useState(false);

  useEffect(() => {
    getAllNotifications();
    // eslint-disable-next-line
  }, []);

  const toggleNotifications = () => {
    setIsNotificaionsOpen(!isNotificaionsOpen);
  };

  return (
    <div className='hn__container' data-testid='header-notifications'>
      <RoundButton
        logo={
          <NotificationIcon
            className={`hn__icon--${
              notifications?.length > 0 ? 'flll' : 'empty'
            }`}
          />
        }
        onClick={toggleNotifications}
      />
      {isNotificaionsOpen && (
        <Fragment>
          <div className='hn'>
            {notifications && notifications.length > 0 ? (
              <Fragment>
                <div className='hn__delete-all'>
                  <button
                    className='hn__btn'
                    onClick={() => deleteAllNotifications(notifications)}>
                    Удалить все
                  </button>
                </div>
                <ul className='hn__list'>
                  {notifications.map((notification, index) => (
                    <li className='hn__list-item' key={index}>
                      <p className='hn__notification-msg'>
                        {notification.message}
                      </p>
                      <button
                        className='hn__btn'
                        onClick={() => deleteNotification(notification)}>
                        Удалить
                      </button>
                    </li>
                  ))}
                </ul>
              </Fragment>
            ) : (
              <p className='hn__no-notifications'>Нет новых уведомлений.</p>
            )}
          </div>
          <div className='hn__background' onClick={toggleNotifications}></div>
        </Fragment>
      )}
    </div>
  );
};
const mapStateToProps = (state) => ({
  notifications: state.notifications,
});
export default connect(mapStateToProps, {
  getAllNotifications,
  deleteAllNotifications,
  deleteNotification,
})(HeaderNotifications);
