import React, { useEffect } from 'react';
import FlatButton from '../../../../layouts/FlatButton/FlatButton';
import TextField from '../../../../layouts/TextField/TextField';
import Tooltip from '../../../../layouts/Tooltip/Tooltip';
import File from '../../../../layouts/File/File';
import Selector from '../../../../layouts/Selector/Selector';
import { Controller, useFieldArray } from 'react-hook-form';
import './LeasingSubject.scss';
import resolveEpts from '../../../../../utils/resolveEpts';
import decodeEpts from '../../../../../services/decodeEpts';

const LeasingSubject = ({
  index,
  register,
  errors,
  control,
  leasingSubjectsList,
  setValue,
  watch,
}) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `leasingSubjects[${index}].files`,
  });

  useEffect(() => {
    if (index > 0) {
      append({
        file: null,
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const lastFileIndex = fields.length - 1;
    const file = watch(
      `leasingSubjects[${index}].files[${lastFileIndex}].file`
    );
    if (file) {
      append({
        file: null,
      });
    }
    // eslint-disable-next-line
  }, [leasingSubjectsList]);

  useEffect(() => {
    let files = watch(`leasingSubjects[${index}].files`);
    if (files) {
      files.forEach((item, idx) => {
        if (idx !== files.length - 1 && !isFileExist(idx)) {
          remove(idx);
        }
      });
    }
    // eslint-disable-next-line
  }, [leasingSubjectsList]);


  const getData = async (pts) => {
    const epts = resolveEpts(await decodeEpts(pts));
    setValue(`leasingSubjects[${index}].vin`, epts.vin);
    setValue(`leasingSubjects[${index}].vinChassis`, epts.vinChassis);
    setValue(`leasingSubjects[${index}].make`, epts.make);
    setValue(`leasingSubjects[${index}].model`, epts.model);
    setValue(`leasingSubjects[${index}].color`, epts.color);
  }

  const isFileExist = (fileIndex) => {
    const file = watch(`leasingSubjects[${index}].files[${fileIndex}].file`);
    if (file !== '') {
      return true;
    }
    return false;
  };

  return (
    <div className='leasing-subject'>
      <div className='leasing-subject__row'>
        <strong>Предмет лизинга №{index + 1}</strong>
        {index > 0 && (
          <FlatButton
            danger
            label='удалить'
            onClick={() => {
              leasingSubjectsList.remove(index);
            }}
          />
        )}
      </div>
      <div className='leasing-subject__row'>
        <span className='leasing-subject__row__label-container'>
          <span className='leasing-subject__row__label'>
            Номер договора лизинга
          </span>
        </span>
        <TextField
          placeholder={'Номер договора лизинга'}
          register={register({
            required: 'Укажите номер договора лизинга.',
            maxLength: {
              value: 64,
              message: 'Максимальная длина 64 символа.',
            },
          })}
          maxLength={64}
          name={`leasingSubjects[${index}].leasingAgreement`}
          error={errors?.leasingSubjects?.[index]?.leasingAgreement}
          setValue={setValue}
          value={watch(`leasingSubjects[${index}].leasingAgreement`)}
        />
      </div>
      <div className='leasing-subject__row'>
        <span className='leasing-subject__row__label-container'>
          <span className='leasing-subject__row__label'>
            Срок договора лизинга
          </span>
        </span>
        <div style={{width: '320px'}}>
          <Selector register={register()} name={`leasingSubjects[${index}].leaseTerm`} />
        </div>
      </div>
      <div className='leasing-subject__row hidden-field'>
        <span className='leasing-subject__row__label-container'>
          <span className='leasing-subject__row__label'>Марка</span>
        </span>
        <TextField
          placeholder={'Марка'}
          register={register()}
          maxLength={64}
          name={`leasingSubjects[${index}].make`}
          error={errors?.leasingSubjects?.[index]?.make}
          setValue={setValue}
          value={watch(`leasingSubjects[${index}].make`)}
        />
      </div>
      <div className='leasing-subject__row hidden-field'>
        <span className='leasing-subject__row__label-container'>
          <span className='leasing-subject__row__label'>Модель</span>
        </span>
        <TextField
          placeholder={'Марка'}
          register={register()}
          maxLength={64}
          name={`leasingSubjects[${index}].model`}
          error={errors?.leasingSubjects?.[index]?.model}
          setValue={setValue}
          value={watch(`leasingSubjects[${index}].model`)}
        />
      </div>
      <div className='leasing-subject__row hidden-field'>
        <span className='leasing-subject__row__label-container'>
          <span className='leasing-subject__row__label'>Цвет</span>
        </span>
        <TextField
          placeholder={'Цвет'}
          register={register()}
          maxLength={64}
          name={`leasingSubjects[${index}].color`}
          error={errors?.leasingSubjects?.[index]?.color}
          setValue={setValue}
          value={watch(`leasingSubjects[${index}].color`)}
        />
      </div>
      <div className='leasing-subject__row hidden-field'>
        <span className='leasing-subject__row__label-container'>
          <span className='leasing-subject__row__label'>Номер шасси</span>
        </span>
        <TextField
          placeholder={'Номер шасси'}
          register={register()}
          maxLength={17}
          replaceChars={/[^0-9a-zA-Z]/g}
          toUpperCase
          name={`leasingSubjects[${index}].vinChassis`}
          error={errors?.leasingSubjects?.[index]?.vinChassis}
          setValue={setValue}
          value={watch(`leasingSubjects[${index}].vinChassis`)}
        />
      </div>
      <div className='leasing-subject__row hidden-field'>
        <span className='leasing-subject__row__label-container'>
          <span className='leasing-subject__row__label'>VIN</span>
        </span>
        <TextField
          placeholder={'VIN'}
          register={register()}
          maxLength={17}
          replaceChars={/[^0-9a-zA-Z]/g}
          toUpperCase
          name={`leasingSubjects[${index}].vin`}
          error={errors?.leasingSubjects?.[index]?.vin}
          setValue={setValue}
          value={watch(`leasingSubjects[${index}].vin`)}
        />
      </div>
      <div className='leasing-subject__row'>
        <span className='leasing-subject__row__label-container'>
          <span className='leasing-subject__row__label'>Файл ПТС</span>
          <Tooltip title={ptsFileTip} />
        </span>
        <Controller
          control={control}
          name={`leasingSubjects[${index}].pts`}
          defaultValue={null}
          rules={{
            required: 'Добавьте файл ПТС.',
            validate: {
              fileSize: (value) =>
                value && value.size < 5000000
                  ? true
                  : 'Размер файла не должен превышать 5 МБ.',
              mimetype: (value) =>
                value &&
                value.type.match(/^image\/(jpe?g|png)|application\/pdf$/g)
                  ? true
                  : 'Неверный формат файла.',
            },
          }}
          render={(props, ref) => (
            <File
              {...props}
              onChange={(file) => {
                setValue(`leasingSubjects[${index}].pts`, file);
                getData(file);
              }}
              ref={ref}
              setValue={setValue}
              label={'Приложить ПТС'}
              error={errors?.leasingSubjects?.[index]?.pts}
            />
          )}
        />
      </div>
      <div className='leasing-subject__row'>
        <span className='leasing-subject__row__label-container'>
          <span className='leasing-subject__row__label'>
            Дополнительные файлы
          </span>
          <Tooltip title={filesTip} />
        </span>
        <div className='leasing-subject__row__files'>
          {fields.map((item, fileIndex) => (
            <Controller
              key={item.id}
              control={control}
              name={`leasingSubjects[${index}].files[${fileIndex}].file`}
              defaultValue={null}
              rules={{
                validate: {
                  fileSize: (value) =>
                    value
                      ? value.size < 5000000 ||
                        'Размер файла не должен превышать 5 МБ.'
                      : true,
                  mimetype: (value) =>
                    value
                      ? value.type.match(
                          /^image\/(jpe?g|png)|application\/pdf$/g
                        ) || 'Неверный формат файла.'
                      : true,
                },
              }}
              render={(props, ref) => (
                <File
                  {...props}
                  ref={ref}
                  setValue={setValue}
                  label={'Выбрать'}
                  error={
                    errors?.leasingSubjects?.[index]?.files?.[fileIndex]?.file
                  }
                />
              )}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const ptsFileTip = (
  <p>
    Прикрепите файл паспорта на транспортное средство. Допустимые форматы файла:
    <strong>pdf</strong>, <strong>jpeg</strong>, <strong>png</strong>. Размер
    файла не должен превышать <strong>5МБ</strong>.
  </p>
);
const filesTip = (
  <p>
    Прикрепите дополнительные файлы к заявке. Допустимые форматы файла:
    <strong>pdf</strong>, <strong>jpeg</strong>, <strong>png</strong>. Размер
    файла не должен превышать <strong>5МБ</strong>.
  </p>
);

export default LeasingSubject;
