import React from 'react';
import './RoundButton.scss';

const RoundButton = ({ logo = null, onClick = null }) => {
  return (
    <button data-testid='rb' className='rb' onClick={onClick}>
      <span data-testid='rb__logo' className='rb__logo'>
        {logo}
      </span>
      <span data-testid='rb__highlight' className='rb__highlight'></span>
    </button>
  );
};

export default RoundButton;
