import React, { Fragment, useEffect } from 'react';
import TextField from '../../../layouts/TextField/TextField';
import { useForm } from 'react-hook-form';
import Button from '../../../layouts/Button/Button';
import { connect } from 'react-redux';
import {
  updatePassword,
  clearNotifications,
  clearErrors,
} from '../../../../actions/authActions';
import ModalLoading from '../../../layouts/modalLoading/ModalLoading';
import { setAlert } from '../../../../actions/alertActions';

const UserSecurity = ({
  updatePassword,
  clearNotifications,
  clearErrors,
  setAlert,
  auth: { notification, error },
  orders: { loading },
}) => {
  const {
    register,
    setValue,
    errors,
    watch,
    handleSubmit,
    setError,
    reset,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      oldPassword: '',
      newPassword: '',
      newPasswordRepeat: '',
    },
  });
  useEffect(() => {
    if (notification) {
      setAlert(notification.message, 'success');
      clearNotifications();
      reset();
    }
    // eslint-disable-next-line
  }, [notification]);

  useEffect(() => {
    if (error) {
      setAlert(error, 'danger');
      clearErrors();
    }
    // eslint-disable-next-line
  }, [error]);

  const changePassword = (data) => {
    const { oldPassword, newPassword, newPasswordRepeat } = data;
    if (oldPassword === newPassword) {
      console.log('error');
      setError('oldPassword', {
        type: 'manual',
        message: 'Старый и новые пароли совпадают.',
      });
      setError('newPassword', {
        type: 'manual',
        message: 'Старый и новые пароли совпадают.',
      });
      return;
    }
    if (newPasswordRepeat !== newPassword) {
      console.log('error');
      setError('newPasswordRepeat', {
        type: 'manual',
        message: 'Пароли не совпадают.',
      });
      setError('newPassword', {
        type: 'manual',
        message: 'Пароли не совпадают.',
      });
      return;
    }
    updatePassword(oldPassword, newPassword);
  };

  return (
    <Fragment>
      {loading && <ModalLoading />}
      <div className='content'>
        <h2>Безопасность</h2>
        <form onSubmit={handleSubmit(changePassword)} className='form'>
          <div className='form__row'>
            <strong>Изменить пароль</strong>
          </div>
          <div className='form__row'>
            <span className='form__row__label-container'>
              <span className='form__row__label'>Старый пароль</span>
            </span>
            <TextField
              maxLength='18'
              type='password'
              placeholder={'старый пароль'}
              register={register({
                required: 'Введите старый пароль.',
                maxLength: {
                  value: 18,
                  message: 'Максимальная длина пароля 18 символов.',
                },
                minLength: {
                  value: 6,
                  message: 'Минимальная длина пароля 6 символов.',
                },
              })}
              name='oldPassword'
              setValue={setValue}
              error={errors.oldPassword}
              value={watch('oldPassword')}
            />
          </div>
          <div className='form__row'>
            <span className='form__row__label-container'>
              <span className='form__row__label'>Новый пароль</span>
            </span>
            <TextField
              maxLength='18'
              type='password'
              placeholder={'новый пароль'}
              register={register({
                required: 'Введите новый пароль.',
                maxLength: {
                  value: 18,
                  message: 'Максимальная длина пароля 18 символов.',
                },
                minLength: {
                  value: 6,
                  message: 'Минимальная длина пароля 6 символов.',
                },
              })}
              name='newPassword'
              setValue={setValue}
              error={errors.newPassword}
              value={watch('newPassword')}
            />
          </div>
          <div className='form__row'>
            <span className='form__row__label-container'>
              <span className='form__row__label'>Новый пароль ещё раз</span>
            </span>
            <TextField
              maxLength='18'
              type='password'
              placeholder={'новый пароль'}
              register={register({
                required: 'Введите новый пароль.',
                maxLength: {
                  value: 18,
                  message: 'Максимальная длина пароля 18 символов.',
                },
                minLength: {
                  value: 6,
                  message: 'Минимальная длина пароля 6 символов.',
                },
              })}
              name='newPasswordRepeat'
              setValue={setValue}
              error={errors.newPasswordRepeat}
              value={watch('newPasswordRepeat')}
            />
          </div>
          <Button type='submit' label='Обновить пароль' />
        </form>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  orders: state.orders,
});

export default connect(mapStateToProps, {
  updatePassword,
  clearNotifications,
  clearErrors,
  setAlert,
})(UserSecurity);
