import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './store';
import Login from './components/pages/login/Login';
import Admin from './components/pages/admin/Admin';
import AdminRoute from './components/routing/AdminRoute';
import UnderwriterRoute from './components/routing/UnderwriterRoute';
import ResetPassword from './components/pages/resetPassword/ResetPassword';
import NotFound from './components/pages/NotFound/NotFound';
import axios from 'axios';

import { gsap } from 'gsap';
import { CSSPlugin } from 'gsap/CSSPlugin';
import setAuthToken from './utils/setAuthToken';
import LessorRoute from './components/routing/LessorRoute';
import LessorDashboard from './components/pages/Lessor/LessorDashboard/LessorDashboard';
import Alert from './components/layouts/Alert/Alert';
import AuthorizedRoute from './components/routing/AuthorizedRoute';
import UserProfile from './components/pages/UserProfile/UserProfile';
import UnderwriterDashboard from './components/pages/Underwriter/UnderwriterDashboard/UnderwriterDashboard';
// Force CSSPlugin to not get dropped during build
gsap.registerPlugin(CSSPlugin);

const url = process.env.REACT_APP_API_URL;

function App() {
  useEffect(() => {
    window.addEventListener('beforeunload', (ev) => {
      if (!JSON.parse(localStorage.rememberMe)) {
        localStorage.removeItem('token');
      }
      return;
    });
    return () => {
      window.addEventListener('beforeunload', (ev) => {
        if (!JSON.parse(localStorage.rememberMe)) {
          localStorage.removeItem('token');
        }
        return;
      });
    };
  }, []);

  useEffect(() => {
    loadUser();
  }, []);

  const loadUser = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      setAuthToken(token);
      try {
        const res = await axios.get(`${url}/api/v2/auth`);
        store.dispatch({ type: 'USER_LOADED', payload: res.data });
      } catch (error) {
        store.dispatch({ type: 'AUTH_ERROR' });
      }
    } else {
      store.dispatch({ type: 'LOGIN_FAIL' });
    }
  };

  return (
    <Provider store={store}>
      <Router>
        <Alert />
        <Switch>
          <Route exact path='/login' component={Login} />
          <AdminRoute path='/admin' component={Admin} />
          <LessorRoute path='/leasing-dashboard/' component={LessorDashboard} />
          <UnderwriterRoute
            path='/insurance-agent-dashboard/'
            component={UnderwriterDashboard}
          />
          <AuthorizedRoute path='/profile' component={UserProfile} />
          <Route exact path='/resetpassword/:id' component={ResetPassword} />
          <Route exact path='/:search' component={NotFound} />
          <Redirect from='/' to='/login' />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
