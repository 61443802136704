import React, { useRef } from 'react';
import { ReactComponent as ClearIcon } from '../../../assets/clear_icon.svg';
import './File.scss';

const File = (props) => {
  const fileInput = useRef(null);

  const onClick = (e) => {
    e.preventDefault();
    fileInput.current.click();
  };

  const onAddFile = (e) => {
    props.onChange(e.target.files[0]);
  };

  const removeFile = (e) => {
    e.preventDefault();
    props.setValue(props.name, '', { shouldValidate: true });
  };

  return (
    <div className={`file file--${props.error && 'error'}`}>
      <input
        type='file'
        className='file__hidden-input'
        onChange={onAddFile}
        ref={fileInput}
        accept='application/pdf,image/jpeg,image/png'
      />
      {props.value ? (
        <div className='file__value'>
          <span className='file__file-name'>{props.value.name}</span>
          <button onClick={removeFile} className='file__delete-file-btn'>
            <ClearIcon />
          </button>
        </div>
      ) : (
        <button className='file__btn' onClick={onClick}>
          {props.label || 'upload'}
        </button>
      )}
      <small className='file__error'>
        {props.error && props.error.message}
      </small>
    </div>
  );
};

export default File;
