import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const UnderwriterRoute = ({
  component: Component,
  auth: { user, isAuthenticated, loading, ...rest },
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (loading) {
          return '';
        }
        if (user && (user.role === 'admin' || user.role === 'insuranceAgent' || user.role === 'headOfInsurance')) {
          return <Component {...props} />;
        }
        if (!isAuthenticated && !loading) {
          return <Redirect to='/login' />;
        }
        return <Redirect to='/login' />;
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(UnderwriterRoute);
