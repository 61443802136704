import setAuthToken from '../utils/setAuthToken';
import axios from 'axios';
import {
  USER_LOADED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_ERRORS,
  REMEMBER_ME,
  EMAIL_SEND_SUCCESS,
  EMAIL_SEND_FAIL,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  CLEAR_NOTIFICATIONS,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAIL,
  LESSOR_ORDER_REQ_START,
  LESSOR_ORDER_REQ_END,
} from './types';

const url = process.env.REACT_APP_API_URL;

export const login = (credentials) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const res = await axios.post(`${url}/api/v2/auth`, credentials, config);

    dispatch({ type: LOGIN_SUCCESS, payload: res.data });
  } catch (err) {
    dispatch({ type: LOGIN_FAIL, payload: err.response.data.error });
  }
};

export const loadUser = () => async (dispatch) => {
  if (localStorage.token) {
    setAuthToken(localStorage.token);
  }
  try {
    const res = await axios.get(`${url}/api/v2/auth`);

    dispatch({ type: USER_LOADED, payload: res.data });
  } catch (err) {
    dispatch({ type: AUTH_ERROR });
  }
};

export const dropPassword = (email) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const res = await axios.post(
      `${url}/api/v2/auth/forgotpassword`,
      email,
      config
    );

    dispatch({ type: EMAIL_SEND_SUCCESS, payload: res.data });
  } catch (err) {
    dispatch({ type: EMAIL_SEND_FAIL, payload: err.response.data.error });
  }
};

export const resetPassword = (newPassword, id) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    const res = await axios.put(
      `${url}/api/v2/auth/resetpassword/${id}`,
      newPassword,
      config
    );

    dispatch({ type: RESET_PASSWORD_SUCCESS, payload: res.data });
  } catch (err) {
    dispatch({ type: RESET_PASSWORD_FAIL, payload: err.response.data.error });
  }
};

export const updatePassword = (oldPassword, newPassword) => async (
  dispatch
) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    dispatch({ type: LESSOR_ORDER_REQ_START });
    const res = await axios.put(
      `${url}/api/v2/auth/update-password`,
      {
        oldPassword,
        newPassword,
      },
      config
    );

    dispatch({ type: UPDATE_PASSWORD_SUCCESS, payload: res.data });
  } catch (err) {
    dispatch({ type: UPDATE_PASSWORD_FAIL, payload: err.response.data.error });
  }
  dispatch({ type: LESSOR_ORDER_REQ_END });
};

export const updateContactDetails = (email, phone) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };

  try {
    dispatch({ type: LESSOR_ORDER_REQ_START });
    const res = await axios.put(
      `${url}/api/v2/auth/update-contact-details`,
      {
        email,
        phone,
      },
      config
    );

    dispatch({ type: UPDATE_PASSWORD_SUCCESS, payload: res.data });
  } catch (err) {
    dispatch({ type: UPDATE_PASSWORD_FAIL, payload: err.response.data.error });
  }
  dispatch({ type: LESSOR_ORDER_REQ_END });
};

export const logout = () => async (dispatch) => dispatch({ type: LOGOUT });

export const rememberMe = (value) => async (dispatch) =>
  dispatch({ type: REMEMBER_ME, payload: value });

export const clearNotifications = () => async (dispatch) =>
  dispatch({ type: CLEAR_NOTIFICATIONS });

export const clearErrors = () => async (dispatch) =>
  dispatch({ type: CLEAR_ERRORS });
