import axios from 'axios';
import {
  GET_NOTIFICATIONS,
  DELETE_ALL_NOTIFICATIONS,
  DELETE_ONE_NOTIFICATION,
} from './types';

const url = process.env.REACT_APP_API_URL;

export const getAllNotifications = () => async (dispatch) => {
  try {
    const res = await axios.get(`${url}/api/v2/notification`);

    dispatch({ type: GET_NOTIFICATIONS, payload: res.data.data });
  } catch (err) {
    console.log(err);
  }
};
export const deleteAllNotifications = (notifications) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    await axios.delete(`${url}/api/v2/notification`, {
      data: { notifications },
      config,
    });

    dispatch({ type: DELETE_ALL_NOTIFICATIONS });
  } catch (err) {
    console.log(err);
  }
};

export const deleteNotification = (notification) => async (dispatch) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    await axios.delete(`${url}/api/v2/notification`, {
      data: { notifications: [notification] },
      config,
    });

    dispatch({ type: DELETE_ONE_NOTIFICATION, payload: notification });
  } catch (err) {
    console.log(err);
  }
};
