import React from 'react';
import './Selector.scss';

const Selector = ({ name, register }) => {
  return (
    <select ref={register} name={name} className='selector'>
      <option value='1'>1 год</option>
      <option value='2'>2 года</option>
      <option value='3'>3 года</option>
      <option value='4'>4 года</option>
      <option value='5'>5 лет</option>
    </select>
  );
};

export default Selector;
