import React, { Fragment } from 'react';
import { ReactComponent as CloseIcon } from '../../../../../assets/cross_icon.svg';
import moment from 'moment-timezone';
import './UnderwriterStatisticModal.scss';

const acceptedStatus = <p>Принята</p>;
const doneStatus = <p>Выполнена</p>;
const deniedStatus = <p>Отменена</p>;
const preparationStatus = <p>В обработке</p>;

const UnderwriterStatisticModal = ({ data, onClose }) => {
  const statuses = {
    0: acceptedStatus,
    1: preparationStatus,
    2: doneStatus,
    3: deniedStatus,
  };

  return (
    data && (
      <Fragment>
        <div className='us-modal__background' onClick={onClose}></div>
        <div className='us-modal'>
          <div className='us-modal__row'>
            <span className='us-modal__header-container'>
              <h2 className='us-modal__header'>Заявка</h2>
              {statuses[data.status]}
            </span>
            <button
              className='us-modal__close'
              onClick={(e) => {
                e.preventDefault();
                onClose();
              }}
            >
              <CloseIcon />
            </button>
          </div>
          <div className='us-modal__vehicle'>
            <div className='us-modal__row'>
              <p className='us-modal__section-name'>Информация об автомобиле</p>
            </div>
            <div className='us-modal__row'>
              <p className='us-modal__label'>Марка/Модель</p>
              <p className='us-modal__value'>{data.makeModel}</p>
            </div>
            <div className='us-modal__row'>
              <p className='us-modal__label'>
                Государственный регистрационный знак
              </p>
              <p className='us-modal__value'>{data.stateRegistrationPlate}</p>
            </div>
            <div className='us-modal__row'>
              <p className='us-modal__label'>VIN</p>
              <p className='us-modal__value'>{data.vin}</p>
            </div>
            <div className='us-modal__row'>
              <p className='us-modal__label'>Тип работ</p>
              <p className='us-modal__value'>
                {data.orderType === 'installation'
                  ? 'установка'
                  : 'тех. обслуживание'}
              </p>
            </div>
            <div className='us-modal__row'>
              <p className='us-modal__label'>Дополнительная информация</p>
              <p className='us-modal__value'>{data.comment || 'нет'}</p>
            </div>
            <div className='us-modal__row'>
              <p className='us-modal__label'>Дата и время установки</p>
              <p className='us-modal__value'>
                {' '}
                {data.closedAt
                  ? moment(data.closedAt).format('DD.MM.YYYY HH:mm')
                  : '-'}
              </p>
            </div>
            <div className='us-modal__row'>
              <p className='us-modal__label'>Информация по установке</p>
              <p className='us-modal__value'>
                {data.installationInfo || 'нет'}
              </p>
            </div>
          </div>
          <div className='us-modal__client-info'>
            <div className='us-modal__row'>
              <p className='us-modal__section-name'>Информация о владельце</p>
            </div>
            <div className='us-modal__row'>
              <p className='us-modal__label'>ФИО владельца</p>
              <p className='us-modal__value'>{data.clientName}</p>
            </div>
            <div className='us-modal__row'>
              <p className='us-modal__label'>Номер телефона владельца</p>
              <p className='us-modal__value'>
                {data?.clientPhone.replace(
                  /([0-9]{1})([0-9]{1,3})([0-9]{1,3})([0-9]{1,4})/gi,
                  '$1 ($2) $3-$4'
                )}
              </p>
            </div>
          </div>
          <div className='us-modal__user'>
            <div className='us-modal__row'>
              <p className='us-modal__section-name'>Создал</p>
            </div>
            <div className='us-modal__row'>
              <p className='us-modal__label'>Логин</p>
              <p className='us-modal__value'>{data?.user?.login}</p>
            </div>
            <div className='us-modal__row'>
              <p className='us-modal__label'>ФИО</p>
              <p className='us-modal__value'>{data?.user?.name}</p>
            </div>
          </div>
        </div>
      </Fragment>
    )
  );
};

export default UnderwriterStatisticModal;
