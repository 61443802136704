import React from 'react';
import LessorStatisticSummary from './LessorStatisticSummary/LessorStatisticSummary';
import LessorStatisticSearchBar from './LessorStatisticSearchBar/LessorStatisticSearchBar';
import LessorStatisticTable from './LessorStatisticTable/LessorStatisticTable';
import { connect } from 'react-redux';
import './LessorStatistic.scss';
import { BarLoader } from 'react-spinners';
import LessorStatisticModal from './LessorStatisticModal/LessorStatisticModal';
import {
  getOrderDetails,
  clearOrderDetails,
} from '../../../../actions/orderActions';

const LessorStatistic = ({
  orders: { orders, loading, orderDetails },
  getOrderDetails,
  clearOrderDetails,
}) => {
  const onOpenDetails = (id) => {
    getOrderDetails(id);
  };
  const onCloseDetails = () => {
    clearOrderDetails();
  };

  return (
    <div className='content'>
      <h2>Статистика</h2>
      <LessorStatisticSummary
        total={orders?.orders?.length && !loading ? orders?.orders?.length : 0}
        done={orders?.done && !loading ? orders?.done : 0}
        canceled={orders?.canceled && !loading ? orders?.canceled : 0}
        preparation={orders?.preparation && !loading ? orders?.preparation : 0}
      />
      <LessorStatisticSearchBar />
      <div className='loading-bar'>
        {loading && <BarLoader width='100%' color='#00a86e' height='2px' />}
      </div>
      {orders && orders.orders.length > 0 && !loading && (
        <LessorStatisticTable
          tableData={orders.orders}
          onClickRow={onOpenDetails}
        />
      )}
      {orders && orders.orders.length === 0 && !loading && (
        <p className='table-plug'>
          Заявок не найдено. Попробуйте изменить параметры поиска.
        </p>
      )}
      <LessorStatisticModal data={orderDetails} onClose={onCloseDetails} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  orders: state.orders,
});

export default connect(mapStateToProps, { getOrderDetails, clearOrderDetails })(
  LessorStatistic
);
