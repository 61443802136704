import {
  GET_NOTIFICATIONS,
  DELETE_ALL_NOTIFICATIONS,
  DELETE_ONE_NOTIFICATION,
} from '../actions/types';

const initialState = {
  notifications: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    case DELETE_ALL_NOTIFICATIONS:
      return {
        ...state,
        notifications: null,
      };
    case DELETE_ONE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.id !== action.payload.id
        ),
      };
    default:
      return state;
  }
};
