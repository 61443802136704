import React, { useState } from 'react';
import { ReactComponent as ToggleArrow } from '../../../../assets/arrow_icon.svg';
import './Drawer.scss';

const Drawer = ({ children, title }) => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleSideNav = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`drawer drawer--${isOpen ? 'opened' : 'closed'}`}>
      <button
        className={`drawer__toggle-btn drawer__toggle-btn--${
          isOpen ? 'opened' : 'closed'
        }`}
        onClick={toggleSideNav}
      >
        <ToggleArrow />
      </button>
      <p
        className={`drawer__title drawer__title--${
          isOpen ? 'opened' : 'closed'
        }`}
      >
        {title || 'Компания'}
      </p>
      <div className='drawer__content'>{children}</div>
      <div
        className={`drawer__footer drawer__footer--${
          isOpen ? 'opened' : 'closed'
        }`}
      >
        <p>Тех.поддержка</p>
        <a href='mailto:support@labyrinth.pro'>support@labyrinth.pro</a>
      </div>
    </div>
  );
};

export default Drawer;
