import React, { useState, useEffect } from 'react';
import './resetpassword.scss';
import { connect } from 'react-redux';
import {
  resetPassword,
  clearErrors,
  clearNotifications,
} from '../../../actions/authActions';

const ResetPassword = ({
  resetPassword,
  clearErrors,
  setAlert,
  match,
  history,
  auth: { error, notification },
}) => {
  const [password, setPassword] = useState('');

  useEffect(() => {
    if (error && error === 'Неверный токен восстановления пароля.') {
      setAlert(error, 'danger');
      clearErrors();
    }
    if (notification && notification === 'Пароль изменён успешно.') {
      setAlert(notification, 'success');
      clearNotifications();
      history.push('/login');
    }
    // eslint-disable-next-line
  }, [error, notification]);

  const onChange = (e) => {
    setPassword(e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    resetPassword({ password }, match.params.id);
  };
  return (
    <div className='reset-password'>
      <form onSubmit={onSubmit} className='reset-password__form'>
        <p className='reset-password__text'>Придумайте новый пароль.</p>
        <div className='skew-input'>
          <input
            type='password'
            className='skew-input__field'
            placeholder='Пароль'
            name='password'
            minLength='6'
            maxLength='18'
            value={password}
            onChange={onChange}
            required
          />
        </div>
        <button className='skew-button'>Подтвердить</button>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  resetPassword,
  clearErrors,
  clearNotifications,
})(ResetPassword);
