import React from 'react';
import { ReactComponent as Logo } from '../../../assets/labyrinth_logo.svg';
import HeaderNotifications from './HeaderNotifications/HeaderNotifications';
import UserMenu from './UserMenu/UserMenu';
import { connect } from 'react-redux';
import './Header.scss';

const Header = ({ auth: { user } }) => {
  return (
    <header className='header'>
      <Logo data-testid='header__logo' />
      <div className='header__menu'>
        <HeaderNotifications />
        <UserMenu />
        <div className='header__user'>
          <span className='header__user__login'>{user.login || 'login'}</span>
          <span className='header__user__name'>
            {user.firstName || 'Имя'} {user.lastName || 'Фамилия'}
          </span>
        </div>
      </div>
    </header>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(Header);
