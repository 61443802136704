import {
  UNDERWRITER_ORDER_ADD_SUCCESS,
  UNDERWRITER_ORDER_ADD_FAIL,
  UNDERWRITER_ORDER_REQ_START,
  UNDERWRITER_ORDER_REQ_END,
  UNDERWRITER_ORDER_CLEAR_ERRORS,
  UNDERWRITER_ORDER_CLEAR_NOTIFICATIONS,
  UNDERWRITER_ORDER_GET_FAIL,
  UNDERWRITER_ORDER_GET_SUCCESS,
  UNDERWRITER_ORDER_DETAILS_GET_FAIL,
  UNDERWRITER_ORDER_DETAILS_GET_SUCCESS,
  UNDERWRITER_ORDER_DETAILS_CLEAR,
} from '../actions/types';

const initialState = {
  orders: null,
  loading: false,
  error: null,
  notification: null,
  orderDetails: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case UNDERWRITER_ORDER_ADD_SUCCESS:
      return {
        ...state,
        notification: action.payload,
      };
    case UNDERWRITER_ORDER_ADD_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case UNDERWRITER_ORDER_REQ_START:
      return {
        ...state,
        loading: true,
      };
    case UNDERWRITER_ORDER_REQ_END:
      return {
        ...state,
        loading: false,
      };
    case UNDERWRITER_ORDER_CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    case UNDERWRITER_ORDER_CLEAR_NOTIFICATIONS:
      return {
        ...state,
        notification: null,
      };
    case UNDERWRITER_ORDER_GET_SUCCESS:
      return {
        ...state,
        orders: action.payload,
      };
    case UNDERWRITER_ORDER_DETAILS_GET_SUCCESS:
      return {
        ...state,
        orderDetails: action.payload,
      };
    case UNDERWRITER_ORDER_DETAILS_GET_FAIL:
      return {
        ...state,
        errors: action.payload,
        orderDetails: null,
      };
    case UNDERWRITER_ORDER_GET_FAIL:
      return {
        ...state,
        orders: null,
      };
    case UNDERWRITER_ORDER_DETAILS_CLEAR:
      return {
        ...state,
        orderDetails: null,
      };
    default:
      return state;
  }
};
