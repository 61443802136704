import axios from 'axios';

const decodeEpts = async (ptsFile) => {
  const fd = new FormData();
  fd.append('file', ptsFile);
  const config = { headers: { 'Content-Type': 'multipart/form-data' } };
  try {
    const response = await axios.post('https://epts.labyrinth.pro/EPTS', fd, config);
    return response.data;
  } catch (e) {
    console.log(e);
  }
};

export default decodeEpts;
