import React, { Fragment, useState, useEffect } from 'react';
import { ReactComponent as EditIcon } from '../../../../assets/edit_icon.svg';
import UpdateContactDetails from '../UpdateContactDetails/UpdateContactDetails';
import ModalLoading from '../../../layouts/modalLoading/ModalLoading';
import { connect } from 'react-redux';
import { setAlert } from '../../../../actions/alertActions';
import {
  clearNotifications,
  clearErrors,
} from '../../../../actions/authActions';
import './UserData.scss';

const UserData = ({
  auth: { user, notification, error },
  orders: { loading },
  setAlert,
  clearNotifications,
  clearErrors,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [newContactDetails, setNewContactDetails] = useState({
    phone: null,
    email: null,
  });

  useEffect(() => {
    if (notification) {
      setAlert(notification.message, 'success');
      clearNotifications();
      setEditMode(false);
    }
    // eslint-disable-next-line
  }, [notification]);

  useEffect(() => {
    if (error) {
      setAlert(error, 'danger');
      clearErrors();
    }
    // eslint-disable-next-line
  }, [error]);

  return (
    <Fragment>
      {loading && <ModalLoading />}
      <div className='content'>
        <h2>Профиль</h2>
        <div className='user-data'>
          <div className='user-data__row'>
            <p className='user-data__section-name'>О себе</p>
          </div>
          <div className='user-data__row'>
            <p className='user-data__label'>Полное имя</p>
            <p className='user-data__value'>
              {user.lastName} {user.firstName} {user.patronymic}
            </p>
          </div>
          <div className='user-data__row'>
            <p className='user-data__label'>Логин</p>
            <p className='user-data__value'>{user.login}</p>
          </div>
          <div className='user-data__row'>
            <p className='user-data__label'>Название компании</p>
            <p className='user-data__value'>{user?.company?.companyName}</p>
          </div>
          <div className='user-data__row'>
            <p className='user-data__label'>Должность</p>
            <p className='user-data__value'>{user?.company?.position}</p>
          </div>
          <div className='user-data__row'>
            <p className='user-data__label'>Регион</p>
            <p className='user-data__value'>{user.region}</p>
          </div>
          <div className='user-data__row'>
            <p className='user-data__label'>Контактные данные</p>
            {!editMode && (
              <p className='user-data__value'>
                <button
                  className='user-data__update-btn'
                  onClick={() => setEditMode(true)}>
                  <span>
                    <EditIcon />
                  </span>
                  <span>изменить</span>
                </button>
              </p>
            )}
          </div>
          {editMode ? (
            <UpdateContactDetails
              setEditMode={setEditMode}
              setNewContactDetails={setNewContactDetails}
              email={
                newContactDetails.email
                  ? newContactDetails.email
                  : user.contactDetails.email
              }
              phone={
                newContactDetails.phone
                  ? newContactDetails.phone
                  : user.contactDetails.phone
              }
            />
          ) : (
            <Fragment>
              <div className='user-data__row'>
                <p className='user-data__label'>Email</p>
                <p className='user-data__value'>
                  {newContactDetails.email
                    ? newContactDetails.email
                    : user?.contactDetails?.email}
                </p>
              </div>
              <div className='user-data__row'>
                <p className='user-data__label'>Телефон</p>
                <p className='user-data__value'>
                  {newContactDetails.phone
                    ? newContactDetails.phone
                    : user?.contactDetails?.phone}
                </p>
              </div>
            </Fragment>
          )}
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
  orders: state.orders,
});

export default connect(mapStateToProps, {
  setAlert,
  clearNotifications,
  clearErrors,
})(UserData);
