import React, { Fragment, useEffect } from 'react';
import TextField from '../../../layouts/TextField/TextField';
import Tooltip from '../../../layouts/Tooltip/Tooltip';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import PhoneInput from '../../../layouts/PhoneInput/PhoneInput';
import DaDataInput from '../../../layouts/daDataInput/DaDataInput';
import DatePicker from '../../../layouts/datePicker/DatePicker';
import moment from 'moment';
import Button from '../../../layouts/Button/Button';
import LeasingSubject from './LeasingSubject/LeasingSubject';
import ModalLoading from '../../../layouts/modalLoading/ModalLoading';
import {
  addOrder,
  clearLessorOrderErrors,
  clearLessorOrderNotifications,
} from '../../../../actions/orderActions';
import { connect } from 'react-redux';
import './LessorNewOrder.scss';
import { setAlert } from '../../../../actions/alertActions';

const LessorNewOrder = ({
  addOrder,
  orders,
  setAlert,
  clearLessorOrderErrors,
  clearLessorOrderNotifications,
}) => {
  const leasingSubjectDefaultValue = {
    leasingAgreement: '',
    make: '',
    model: '',
    color: '',
    vin: '',
    vinChassis: '',
    pts: null,
    files: [{ file: null }],
  };
  const {
    register,
    handleSubmit,
    errors,
    setValue,
    control,
    reset,
    watch,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      leasingRecipient: '',
      contact: {
        name: '',
        phone: '',
      },
      installationAddress: '',
      startDate: moment()
        .set({ hours: 8, minutes: 0 })
        .format('DD.MM.YYYY HH:mm'),
      endDate: moment()
        .add(5, 'days')
        .set({ hours: 8, minutes: 0 })
        .format('DD.MM.YYYY HH:mm'),
      leasingSubjects: [leasingSubjectDefaultValue],
    },
  });
  const leasingSubjects = useFieldArray({
    control,
    name: 'leasingSubjects',
    focusAppend: true,
  });

  useEffect(() => {
    if (orders.notifications) {
      setAlert(orders.notifications, 'success');
      clearLessorOrderNotifications();
      reset();
    }
    // eslint-disable-next-line
  }, [orders.notifications]);

  useEffect(() => {
    if (orders.errors) {
      setAlert(orders.errors, 'danger');
      clearLessorOrderErrors();
    }
    // eslint-disable-next-line
  }, [orders.errors]);

  const submitForm = (data) => {
    let fd = buildFormData(data);
    addOrder(fd);
  };

  const buildFormData = (data) => {
    let fd = new FormData();
    fd.append('contact', JSON.stringify(data.contact));
    fd.append('leasingRecipient', data.leasingRecipient);
    fd.append('installationAddress', data.installationAddress);
    fd.append('startDate', data.startDate);
    fd.append('endDate', data.endDate);
    fd.append('comment', data.comment);
    data.leasingSubjects.forEach((item, index) => {
      item.files.forEach((itemFile) => {
        if (itemFile.file) {
          fd.append(`file[${index}]`, itemFile.file, itemFile.file.name);
        }
      });
      fd.append(`pts[${index}]`, item.pts, item.pts.name);
      delete item.pts;
      delete item.files;
    });
    fd.append('leasingSubjects', JSON.stringify(data.leasingSubjects));
    return fd;
  };

  return (
    <Fragment>
      {orders.loading && <ModalLoading />}
      <div className='content'>
        <h2>Новая заявка на установку</h2>
        <form onSubmit={handleSubmit(submitForm)} className='form'>
          <div className='form__row'>
            <span className='form__row__label-container'>
              <span className='form__row__label'>Лизингополучатель</span>
              <Tooltip title={leasingAgreementTip} />
            </span>
            <TextField
              placeholder={'лизингополучатель'}
              register={register({ required: 'Введите лизингополучаетля.',
              maxLength: {
                value: 64,
                message: 'Максимальная длина 64 символа.'
              }
              })}
              maxLength={64}
              name='leasingRecipient'
              setValue={setValue}
              error={errors.leasingRecipient}
              value={watch('leasingRecipient')}
            />
          </div>
          <div className='form__row'>
            <strong>Предметы лизинга</strong>
          </div>
          {leasingSubjects.fields.map((leasingSubject, index) => (
            <LeasingSubject
              key={leasingSubject.id}
              leasingSubjectsList={leasingSubjects}
              index={index}
              register={register}
              errors={errors}
              control={control}
              setValue={setValue}
              watch={watch}
            />
          ))}
          <div className='form__row'>
            <Button
              label='Добавить предмет лизинга'
              onClick={() => {
                leasingSubjects.append(leasingSubjectDefaultValue, false);
              }}
            />
          </div>
          <div className='form__row'>
            <strong>Информация по установке</strong>
          </div>
          <div className='form__row'>
            <span className='form__row__label-container'>
              <span className='form__row__label'>
                ФИО/должность контактного лица
              </span>
            </span>
            <TextField
              placeholder={'ФИО/должность контактного лица'}
              register={register({
                required: 'Введите ФИО и/или должность контактного лица.',
                maxLength: {
                  value: 64,
                  message: 'Максимальная длина 64 символа.'
                }
              })}
              maxLength={64}
              replaceChars={/[0-9a-zA-Z]/g}
              name='contact.name'
              setValue={setValue}
              error={errors?.contact?.name}
              value={watch('contact.name')}
            />
          </div>
          <div className='form__row'>
            <span className='form__row__label-container'>
              <span className='form__row__label'>
                Номер телефона контактного лица
              </span>
            </span>
            <PhoneInput
              name='contact.phone'
              register={register({
                required: 'Введите номер телефона.',
                pattern: {
                  value: /^\+[7]\s\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/,
                  message: 'Введите номер телефона.',
                },
              })}
              setValue={setValue}
              error={errors?.contact?.phone}
              value={watch('contact.phone')}
            />
          </div>
          <div className='form__row'>
            <span className='form__row__label-container'>
              <span className='form__row__label'>Адрес установки</span>
            </span>
            <Controller
              control={control}
              name={'installationAddress'}
              rules={{ required: 'Укажите адрес установки.' }}
              render={(props, ref) => (
                <DaDataInput
                  {...props}
                  ref={ref}
                  title='Адрес установки'
                  name='installationAddress'
                  type='address'
                  setValue={setValue}
                  error={errors.installationAddress}
                />
              )}
            />
          </div>
          <div className='form__row'>
            <span className='form__row__label-container'>
              <span className='form__row__label'>
                Дата и время начала установки
              </span>
            </span>
            <Controller
              control={control}
              name='startDate'
              defaultValue={''}
              render={(props, ref) => (
                <DatePicker
                  {...props}
                  ref={ref}
                  title={'Дата и время начала установки'}
                  value={watch('startDate')}
                  startDate={watch('startDate')}
                  endDate={watch('endDate')}
                  timePicker={true}
                  top={true}
                />
              )}
            />
          </div>
          <div className='form__row'>
            <span className='form__row__label-container'>
              <span className='form__row__label'>
                Дата и время окончания установки
              </span>
            </span>
            <Controller
              control={control}
              name='endDate'
              defaultValue={''}
              render={(props, ref) => (
                <DatePicker
                  {...props}
                  ref={ref}
                  title={'Дата и время окончания начала установки'}
                  value={watch('endDate')}
                  startDate={watch('startDate')}
                  endDate={watch('endDate')}
                  timePicker={true}
                  top={true}
                />
              )}
            />
          </div>
          <div className='form__row'>
            <span className='form__row__label-container'>
              <span className='form__row__label'>
                Дополнительная информация
              </span>
              <Tooltip width={340} title={commentTip} />
            </span>
            <TextField
              textarea
              placeholder={'Введите дополнительную информацию...'}
              register={register(
                {maxLength: { value: 300, message: 'Максимальная длина 300 символов.'}}
              )}
              name='comment'
              setValue={setValue}
              value={watch('comment')}
              error={errors?.comment}
            />
          </div>
          <Button type='submit' label='Отправить заявку' />
        </form>
      </div>
    </Fragment>
  );
};

const leasingAgreementTip = (
  <Fragment>
    <strong>Как в договоре лизинга.</strong>
    <p>Заполняйте поле без использования кавычек. </p>
  </Fragment>
);
const commentTip = (
  <Fragment>
    <strong>Что писать?</strong>
    <p>
      Здесь вы можете добавить уточняющую информацию по установке. Например:
      режим работы дилерского центра или контактного лица.
    </p>
  </Fragment>
);

const mapStateToProps = (state) => ({
  orders: state.orders,
  auth: state.auth,
});

export default connect(mapStateToProps, {
  addOrder,
  setAlert,
  clearLessorOrderErrors,
  clearLessorOrderNotifications,
})(LessorNewOrder);
