import React, { Fragment } from 'react';
import { Switch } from 'react-router-dom';
import AdminRoute from '../../routing/AdminRoute';
import Header from '../../layouts/Header/Header';
import Drawer from '../../layouts/Drawer/Drawer/Drawer';
import DrawerContainer from '../../layouts/Drawer/DrawerContainer/DrawerContainer';
import DrawerNavLink from '../../layouts/Drawer/DrawerNavLink/DrawerNavLink';
import { ReactComponent as AddOrderIcon } from '../../../assets/add-order_icon.svg';
import { ReactComponent as ExternalLinkIcon } from '../../../assets/external-link_icon.svg';
import { ReactComponent as StatisticIcon } from '../../../assets/statistic_icon.svg';
import './admin.scss';

const Admin = () => {
  return (
    <Fragment>
      <Header />
      <DrawerContainer>
        <Drawer>
          <DrawerNavLink
            to={'/admin/lol1'}
            icon={AddOrderIcon}
            name={'Новая заявка'}
          />
          <DrawerNavLink
            to={'/admin/lol2'}
            icon={StatisticIcon}
            name={'Статистика'}
          />
          <DrawerNavLink
            to={'/admin/lol3'}
            icon={AddOrderIcon}
            tailIcon={ExternalLinkIcon}
            name={'Мониноринг'}
          />
        </Drawer>
        <div>
          <Switch>
            <AdminRoute path='/admin/lol1' component={lol1} />
            <AdminRoute path='/admin/lol2' component={lol2} />
            <AdminRoute path='/admin/lol3' component={lol3} />
          </Switch>
        </div>
      </DrawerContainer>
    </Fragment>
  );
};

const lol1 = () => {
  return <p>lol1</p>;
};
const lol2 = () => {
  return <p>Статистика</p>;
};
const lol3 = () => {
  return <p>lol3</p>;
};

export default Admin;
