import React, { Fragment, useState } from 'react'
import UnderwriterTeamMember from './UnderwriterTeamMember/UnderwriterTeamMember';
import { connect } from 'react-redux';

const UnderwriterTeam = ({ auth: { user } }) => {
  const [member, setMember] = useState(null);
  return (
    <Fragment>
    <div className='content'>
      <h2>Команда</h2>
      {user.subordinates && user.subordinates.length > 0 ? (
        <table className='team__table'>
          <thead>
            <tr className='team__table__row'>
              <th className='team__table__header'>Логин</th>
              <th className='team__table__header'>ФИО</th>
              <th className='team__table__header'>Регион</th>
              <th className='team__table__header'>Должность</th>
            </tr>
          </thead>
          <tbody>
            {user.subordinates.map((member, index) => (
              <tr
                className='team__table__row'
                onClick={() => setMember(member)}
                key={index}>
                <td className='team__table__data'>{member.login}</td>
                <td className='team__table__data'>
                  {`${member.lastName}
                  ${member.firstName}
                  ${member.patronymic}`}
                </td>
                <td className='team__table__data'>
                  {`${member.region}`}
                </td>
                <td className='team__table__data'>
                  {`${member.position}`}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className='team__plug'>У Вас ещё нет команды.</p>
      )}
    </div>
    <UnderwriterTeamMember teamMember={member} setMember={setMember} />
  </Fragment>
  )
}

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(UnderwriterTeam);
