import React from 'react';
import { NavLink } from 'react-router-dom';
import './DrawerNavLink.scss';

const DrawerNavLink = ({
  to,
  name,
  icon: Icon = null,
  tailIcon: TailIcon = null,
  external,
}) => {
  if (external) {
    return (
      <a
        target='_blank'
        href={to || ''}
        className='drawer-link'
        rel='noopener noreferrer'
      >
        <span className='drawer-link__icon'>{Icon && <Icon />}</span>
        <span className='drawer-link__name'>{name || 'Ссылка'}</span>
        {TailIcon && <TailIcon />}
      </a>
    );
  } else {
    return (
      <NavLink
        to={to || ''}
        className='drawer-link'
        activeClassName='drawer-link--active'
      >
        <span className='drawer-link__icon'>{Icon && <Icon />}</span>
        <span className='drawer-link__name'>{name || 'Ссылка'}</span>
        {TailIcon && <TailIcon />}
      </NavLink>
    );
  }
};

export default DrawerNavLink;
