import React from 'react';
import { connect } from 'react-redux';
import { BarLoader } from 'react-spinners';
import LessorStatisticSummary from '../../Lessor/LessorStatistic/LessorStatisticSummary/LessorStatisticSummary';
import UnderwriterSearchbar from './UnderwiterSearchbar/UnderwriterSearchbar';
import UnderwriterStatisticModal from './UnderwriterStatisticModal/UnderwriterStatisticModal';
import UnderwriterStatisticTable from './UnderwriterStatisticTable/UnderwriterStatisticTable';
import {
  clearOrderDetails,
  getOrderDetails,
} from '../../../../actions/underwriterOrderActions';

const UnderwriterStatistic = ({
  underwriter: { orders, loading, orderDetails },
  clearOrderDetails,
  getOrderDetails,
}) => {
  const onOpenDetails = (id) => {
    getOrderDetails(id);
  };
  const onCloseDetails = () => {
    clearOrderDetails();
  };
  return (
    <div className='content'>
      <h2>Статистика</h2>
      <LessorStatisticSummary
          total={orders?.orders?.length && !loading ? orders?.orders?.length : 0}
          done={orders?.done && !loading ? orders?.done : 0}
          canceled={orders?.canceled && !loading ? orders?.canceled : 0}
          preparation={orders?.preparation && !loading ? orders?.preparation : 0}
      />
      <UnderwriterSearchbar />
      <div className='loading-bar'>
        {loading && <BarLoader width='100%' color='#00a86e' height='2px' />}
      </div>
      {orders && orders.orders.length > 0 && (
        <UnderwriterStatisticTable
          tableData={orders.orders}
          onClickRow={onOpenDetails}
        />
      )}
      <UnderwriterStatisticModal data={orderDetails} onClose={onCloseDetails} />
    </div>
  );
};

const mapStateToProps = (state) => ({
  underwriter: state.underwriter,
});

export default connect(mapStateToProps, { clearOrderDetails, getOrderDetails })(
  UnderwriterStatistic
);
