import React from 'react';
import { ReactComponent as HelpIcon } from '../../../assets/help_icon.svg';
import './Tooltip.scss';

const Tooltip = ({ width = '240', title = '' }) => {
  return (
    <button className='tooltip'>
      <span className='tooltip__icon'>
        <HelpIcon />
      </span>
      <span className='tooltip__title' style={{ width: `${width}px` }}>
        {title}
      </span>
    </button>
  );
};

export default Tooltip;
