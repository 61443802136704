import React from 'react';
import './Button.scss';

const Button = ({
  type = 'button',
  small = false,
  color,
  label = '',
  onClick = null,
}) => {
  const onButtonClick = (e) => {
    e.preventDefault();
    if (onClick) {
      onClick();
    }
  };
  if (type === 'submit') {
    return (
      <input
        className={`button button--${small ? 'small' : 'common'}`}
        style={{ background: color || '#41b7b0' }}
        type='submit'
        value={label}
      />
    );
  } else {
    return (
      <button
        className={`button button--${small ? 'small' : 'common'}`}
        style={{ background: color || '#41b7b0' }}
        onClick={onButtonClick}
      >
        {label}
      </button>
    );
  }
};

export default Button;
