import { combineReducers } from 'redux';
import authReducer from './authReducer';
import orderReducer from './orderReducer';
import underwriterReducer from './underwriterReducer';
import alertReducer from './alertReducer';
import notificationReducer from './notificationReducer';

export default combineReducers({
  auth: authReducer,
  orders: orderReducer,
  underwriter: underwriterReducer,
  alerts: alertReducer,
  notifications: notificationReducer,
});
