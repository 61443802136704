export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const CLEAR_NOTIFICATIONS = 'CLEAR_NOTIFICATIONS';

export const REMEMBER_ME = 'REMEMBER_ME';

export const EMAIL_SEND_SUCCESS = 'EMAIL_SEND_SUCCESS';
export const EMAIL_SEND_FAIL = 'EMAIL_SEND_FAIL';

export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';

export const UPDATE_PASSWORD_SUCCESS = ' UPDATE_PASSWORD_SUCCESS';
export const UPDATE_PASSWORD_FAIL = ' UPDATE_PASSWORD_FAIL';

export const LESSOR_ORDER_GET_SUCCESS = 'LESSOR_ORDER_GET_SUCCESS';
export const LESSOR_ORDER_GET_FAIL = 'LESSOR_ORDER_GET_FAIL';
export const LESSOR_ORDER_ADD_SUCCESS = 'ORDER_ADD_SUCCESS';
export const LESSOR_ORDER_ADD_FAIL = 'ORDER_ADD_FAIL';
export const LESSOR_ORDER_REQ_START = 'ORDER_REQ_START';
export const LESSOR_ORDER_REQ_END = 'ORDER_REQ_END';
export const LESSOR_ORDER_CLEAR_ERRORS = 'LESSOR_ORDER_CLEAR_ERRORS';
export const LESSOR_ORDER_CLEAR_NOTIFICATIONS =
  'LESSOR_ORDER_CLEAR_NOTIFICATIONS';
export const LESSOR_ORDER_DETAILS_GET_SUCCESS =
  'LESSOR_ORDER_DETAILS_GET_SUCCESS';
export const LESSOR_ORDER_DETAILS_GET_FAIL = 'LESSOR_ORDER_DETAILS_GET_FAIL';
export const LESSOR_ORDER_DETAILS_CLEAR = 'LESSOR_ORDER_DETAILS_CLEAR';

export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

export const UNDERWRITER_ORDER_ADD_SUCCESS = 'UNDERWRITER_ORDER_ADD_SUCCESS';
export const UNDERWRITER_ORDER_ADD_FAIL = 'UNDERWRITER_ORDER_ADD_FAIL';
export const UNDERWRITER_ORDER_REQ_START = 'UNDERWRITER_ORDER_REQ_START';
export const UNDERWRITER_ORDER_REQ_END = 'UNDERWRITER_ORDER_REQ_END';
export const UNDERWRITER_ORDER_CLEAR_ERRORS = 'UNDERWRITER_ORDER_CLEAR_ERRORS';
export const UNDERWRITER_ORDER_CLEAR_NOTIFICATIONS =
  'UNDERWRITER_ORDER_CLEAR_NOTIFICATIONS';
export const UNDERWRITER_ORDER_DETAILS_GET_SUCCESS =
  'UNDERWRITER_ORDER_DETAILS_GET_SUCCESS';
export const UNDERWRITER_ORDER_DETAILS_GET_FAIL =
  'UNDERWRITER_ORDER_DETAILS_GET_FAIL';
export const UNDERWRITER_ORDER_DETAILS_CLEAR =
  'UNDERWRITER_ORDER_DETAILS_CLEAR';
export const UNDERWRITER_ORDER_GET_SUCCESS = 'UNDERWRITER_ORDER_GET_SUCCESS';
export const UNDERWRITER_ORDER_GET_FAIL = 'UNDERWRITER_ORDER_GET_FAIL';

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
export const DELETE_ALL_NOTIFICATIONS = 'DELETE_ALL_NOTIFICATIONS';
export const DELETE_ONE_NOTIFICATION = 'DELETE_ONE_NOTIFICATION';
