import React from 'react';
import { ReactComponent as ClearIcon } from '../../../assets/clear_icon.svg';
import './phoneInput.scss';

const PhoneInput = ({ name, error, register, setValue, value }) => {
  let hiddenInput = null;

  const onChangePhone = (e) => {
    hiddenInput.value = e.target.value.replace(/(\+7)/gi, '');
    hiddenInput.value = hiddenInput.value.replace(/\D/gi, '');
    if (hiddenInput.value.length > 10) {
      hiddenInput.value = hiddenInput.value.slice(1, 11);
    }
    e.target.value = formatePhone(hiddenInput.value);
  };

  const onPaste = (e) => {
    e.target.value = e.clipboardData.getData('Text');
    onChangePhone(e);
  };

  const onFocus = (e) => {
    if (e.target.value.length === 0) {
      e.target.value = '+7 (';
    }
  };

  const onBlur = (e) => {
    if (hiddenInput.value.length === 0) {
      e.target.value = '';
    }
  };

  const clear = (e) => {
    e.preventDefault();
    if (setValue) {
      setValue(name, '', { shouldValidate: true });
    }
  };

  const formatePhone = (value) => {
    if (value.length <= 3) {
      return value.replace(/([0-9]{1,3})/gi, '+7 ($1');
    }
    if (value.length > 3 && value.length <= 6) {
      return value.replace(/([0-9]{1,3})([0-9]{1,3})/gi, '+7 ($1) $2');
    }
    if (value.length > 6) {
      return value.replace(
        /([0-9]{1,3})([0-9]{1,3})([0-9]{1,4})/gi,
        '+7 ($1) $2-$3'
      );
    }
    return value;
  };

  return (
    <div className='phone-field__container'>
      <div className={`phone-field phone-field--${error && 'error'}`}>
        <input
          onChange={onChangePhone}
          onFocus={onFocus}
          onBlur={onBlur}
          onPaste={onPaste}
          placeholder='Номер телефона'
          className='phone-field__input'
          type='tel'
          maxLength='17'
          name={name}
          ref={register}
        />
        <input
          type='text'
          className='phone-field__input phone-field__input--hidden'
          ref={(e) => (hiddenInput = e)}
          maxLength='17'
        />
        {value.replace(/(\+7)/gi, '').length > 0 && (
          <button className='phone-field__clear-btn' onClick={clear}>
            <ClearIcon />
          </button>
        )}
      </div>
      {error && error.message && (
        <small className='phone-field__error'>{error.message}</small>
      )}
    </div>
  );
};

export default PhoneInput;
