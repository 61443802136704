import React, { Fragment, useState, useEffect } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import Button from '../../../layouts/Button/Button';
import CheckBox from '../../../layouts/CheckBox/CheckBox';
import PhoneInput from '../../../layouts/PhoneInput/PhoneInput';
import TextField from '../../../layouts/TextField/TextField';
import Tooltip from '../../../layouts/Tooltip/Tooltip';
import File from '../../../layouts/File/File';
import { connect } from 'react-redux';
import {
  addUnderwriterOrder,
  clearUnderwriterOrderErrors,
  clearUnderwriterOrderNotifications,
} from '../../../../actions/underwriterOrderActions';
import ModalLoading from '../../../layouts/modalLoading/ModalLoading';
import { setAlert } from '../../../../actions/alertActions';
import './UnderwriterNewOrder.scss';

const UnderwriterNewOrder = ({
  underwriter: { error, notification, loading },
  addUnderwriterOrder,
  setAlert,
  clearUnderwriterOrderNotifications,
  clearUnderwriterOrderErrors,
}) => {
  const [orderTypeFlag, setOrderTypeFlag] = useState(true);
  const {
    register,
    handleSubmit,
    errors,
    watch,
    reset,
    setValue,
    control,
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      makeModel: '',
      stateRegistrationPlate: '',
      vin: '',
      orderType: 'installation',
      comment: '',
      clientName: '',
      clientPhone: '',
      pts: null,
      registrationCertificate: null,
      files: [{ file: null }],
    },
  });
  const { fields, remove, append } = useFieldArray({
    control,
    name: `files`,
  });

  useEffect(() => {
    const lastFileIndex = fields.length - 1;
    const file = watch(`files[${lastFileIndex}].file`);
    if (file) {
      append({
        file: null,
      });
    }
    // eslint-disable-next-line
  }, [watch('files')]);

  useEffect(() => {
    let files = watch(`files`);
    if (files) {
      files.forEach((item, idx) => {
        if (idx !== files.length - 1 && !isFileExist(idx)) {
          remove(idx);
        }
      });
    }
    // eslint-disable-next-line
  }, [watch('files')]);

  const isFileExist = (fileIndex) => {
    const file = watch(`files[${fileIndex}].file`);
    if (file !== '') {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (notification) {
      setAlert(notification, 'success');
      clearUnderwriterOrderNotifications();
      reset();
    }
    // eslint-disable-next-line
  }, [notification]);

  useEffect(() => {
    if (error) {
      setAlert(error, 'danger');
      clearUnderwriterOrderErrors();
    }
    // eslint-disable-next-line
  }, [error]);

  const submitForm = (data) => {
    const fd = createFormData(data);
    addUnderwriterOrder(fd);
  };

  const createFormData = (data) => {
    let fd = new FormData();
    fd.append('stateRegistrationPlate', data.stateRegistrationPlate);
    fd.append('makeModel', data.makeModel);
    fd.append('comment', data.comment);
    fd.append('clientPhone', data.clientPhone);
    fd.append('clientName', data.clientName);
    fd.append('vin', data.vin);
    fd.append('orderType', orderTypeFlag ? 'installation' : 'service');
    fd.append('pts', data.pts, data.pts.name);
    fd.append(
      'registrationCertificate',
      data.registrationCertificate,
      data.registrationCertificate.name
    );
    if (data.files) {
      data.files.forEach((item, index) => {
        if (item.file) {
          fd.append(`file[${index}]`, item.file, item.file.name);
        }
      });
    }
    return fd;
  };

  return (
    <Fragment>
      {loading && <ModalLoading />}
      <div className='content'>
        <h2>Новая заявка</h2>
        <form onSubmit={handleSubmit(submitForm)} className='form'>
          <div className='form__row'>
            <strong>Информация об автомобиле</strong>
          </div>
          <div className='form__row'>
            <span className='form__row__label-container'>
              <div className='form__row__label'>Марка/Модель</div>
            </span>
            <TextField
              placeholder='марка/модель'
              register={register({
                required: 'Введите марку и модель автомобиля.',
              })}
              name='makeModel'
              setValue={setValue}
              error={errors.makeModel}
              value={watch('makeModel')}
            />
          </div>
          <div className='form__row'>
            <span className='form__row__label-container'>
              <div className='form__row__label'>
                Государственный регистрационный знак
              </div>
            </span>
            <TextField
              placeholder='Государственный регистрационный знак'
              register={register({
                required:
                  'Введите государственный регистрационный знак автомобиля.',
              })}
              name='stateRegistrationPlate'
              setValue={setValue}
              error={errors.stateRegistrationPlate}
              value={watch('stateRegistrationPlate')}
            />
          </div>
          <div className='form__row'>
            <span className='form__row__label-container'>
              <span className='form__row__label'>VIN</span>
            </span>
            <TextField
              placeholder={'vin'}
              register={register({
                required: 'Укажите vin автомобиля.',
                minLength: {
                  value: 17,
                  message: 'Минимальная длина - 17 символов.',
                },
                maxLength: {
                  value: 17,
                  message: 'Максимальная длина - 17 символов.',
                },
              })}
              maxLength={17}
              replaceChars={/[^0-9a-zA-Z]/g}
              toUpperCase
              name='vin'
              error={errors?.vin}
              setValue={setValue}
              value={watch('vin')}
            />
          </div>
          <div className='form__row'>
            <span className='form__row__label-container'>
              <div className='form__row__label'>Направляется для</div>
            </span>
            <div style={{ width: '320px' }}>
              <CheckBox
                name='orderType'
                id='orderType'
                checked={orderTypeFlag}
                label='Установка поисковой системы'
                onChange={() => {
                  setOrderTypeFlag(!orderTypeFlag);
                }}
              />
              <CheckBox
                name='orderType'
                id='orderType'
                checked={!orderTypeFlag}
                label='Тех. обслуживание'
                onChange={() => {
                  setOrderTypeFlag(!orderTypeFlag);
                }}
              />
            </div>
          </div>
          <div className='form__row'>
            <span className='form__row__label-container'>
              <span className='form__row__label'>
                Дополнительная информация
              </span>
              <Tooltip width={340} title={commentTip} />
            </span>
            <TextField
              textarea
              placeholder={'Введите дополнительную информацию...'}
              register={register()}
              name='comment'
              setValue={setValue}
              value={watch('comment')}
              error={errors?.comment}
            />
          </div>
          <div className='form__row'>
            <strong>Информация о владельце</strong>
          </div>
          <div className='form__row'>
            <span className='form__row__label-container'>
              <div className='form__row__label'>ФИО владельца</div>
            </span>
            <TextField
              placeholder='ФИО владельца'
              register={register({
                required: 'Введите ФИО владельца автомобиля.',
              })}
              replaceChars={/[0-9a-zA-Z]/g}
              name='clientName'
              setValue={setValue}
              error={errors.clientName}
              value={watch('clientName')}
            />
          </div>
          <div className='form__row'>
            <span className='form__row__label-container'>
              <span className='form__row__label'>Номер телефона владельца</span>
            </span>
            <PhoneInput
              name='clientPhone'
              register={register({
                required: 'Введите номер телефона.',
                pattern: {
                  value: /^\+[7]\s\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/,
                  message: 'Введите номер телефона.',
                },
              })}
              setValue={setValue}
              error={errors.clientPhone}
              value={watch('clientPhone')}
            />
          </div>
          <div className='form__row'>
            <strong>Документы</strong>
          </div>
          <div className='form__row'>
            <span className='form__row__label-container'>
              <span className='form__row__label'>Файл ПТС</span>
              <Tooltip title={ptsFileTip} />
            </span>
            <Controller
              control={control}
              name='pts'
              defaultValue={null}
              rules={{
                required: 'Добавьте файл ПТС.',
                validate: {
                  fileSize: (value) =>
                    value && value.size < 5000000
                      ? true
                      : 'Размер файла не должен превышать 5 МБ.',
                  mimetype: (value) =>
                    value &&
                    value.type.match(/^image\/(jpe?g|png)|application\/pdf$/g)
                      ? true
                      : 'Неверный формат файла.',
                },
              }}
              render={(props, ref) => (
                <File
                  {...props}
                  ref={ref}
                  setValue={setValue}
                  label={'Приложить ПТС'}
                  error={errors.pts}
                />
              )}
            />
          </div>
          <div className='form__row'>
            <span className='form__row__label-container'>
              <span className='form__row__label'>
                Свидетельство о регистрации
              </span>
              <Tooltip title={registrationCertificateTip} />
            </span>
            <Controller
              control={control}
              name='registrationCertificate'
              defaultValue={null}
              rules={{
                required: 'Добавьте свидетельство о регистрации.',
                validate: {
                  fileSize: (value) =>
                    value && value.size < 5000000
                      ? true
                      : 'Размер файла не должен превышать 5 МБ.',
                  mimetype: (value) =>
                    value &&
                    value.type.match(/^image\/(jpe?g|png)|application\/pdf$/g)
                      ? true
                      : 'Неверный формат файла.',
                },
              }}
              render={(props, ref) => (
                <File
                  {...props}
                  ref={ref}
                  setValue={setValue}
                  label={'Приложить свидетельство'}
                  error={errors.registrationCertificate}
                />
              )}
            />
          </div>
          <div className='form__row'>
            <span className='form__row__label-container'>
              <span className='form__row__label'>Дополнительные файлы</span>
              <Tooltip title={filesTip} />
            </span>
            <div className='leasing-subject__row__files'>
              {fields.map((item, fileIndex) => (
                <Controller
                  key={item.id}
                  control={control}
                  name={`files[${fileIndex}].file`}
                  defaultValue={null}
                  rules={{
                    validate: {
                      fileSize: (value) =>
                        value
                          ? value.size < 5000000 ||
                            'Размер файла не должен превышать 5 МБ.'
                          : true,
                      mimetype: (value) =>
                        value
                          ? value.type.match(
                              /^image\/(jpe?g|png)|application\/pdf$/g
                            ) || 'Неверный формат файла.'
                          : true,
                    },
                  }}
                  render={(props, ref) => (
                    <File
                      {...props}
                      ref={ref}
                      setValue={setValue}
                      label={'Выбрать'}
                      error={errors?.files?.[fileIndex]?.file}
                    />
                  )}
                />
              ))}
            </div>
          </div>
          <Button type='submit' label='Отправить заявку' />
        </form>
      </div>
    </Fragment>
  );
};

const commentTip = (
  <Fragment>
    <strong>Что писать?</strong>
    <p>
      Здесь вы можете добавить уточняющую информацию по установке. Например:
      режим работы дилерского центра или контактного лица.
    </p>
  </Fragment>
);

const ptsFileTip = (
  <p>
    Прикрепите файл паспорта на транспортное средство. Допустимые форматы файла:
    <strong>pdf</strong>, <strong>jpeg</strong>, <strong>png</strong>. Размер
    файла не должен превышать <strong>5МБ</strong>.
  </p>
);

const registrationCertificateTip = (
  <p>
    Прикрепите файл свидетельства о регистрации. Допустимые форматы файла:
    <strong>pdf</strong>, <strong>jpeg</strong>, <strong>png</strong>. Размер
    файла не должен превышать <strong>5МБ</strong>.
  </p>
);

const filesTip = (
  <p>
    Прикрепите дополнительные файлы к заявке. Допустимые форматы файла:
    <strong>pdf</strong>, <strong>jpeg</strong>, <strong>png</strong>. Размер
    файла не должен превышать <strong>5МБ</strong>.
  </p>
);

const mapStateToProps = (state) => ({
  underwriter: state.underwriter,
});

export default connect(mapStateToProps, {
  addUnderwriterOrder,
  clearUnderwriterOrderNotifications,
  clearUnderwriterOrderErrors,
  setAlert,
})(UnderwriterNewOrder);
