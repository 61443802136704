import React from 'react';
import './NotFound.scss';

const NotFound = () => {
  return (
    <div data-testid='not-found-page' className='nf__page'>
      <span className='nf__bg-sign'>404</span>
      <div className='nf__content'>
        <h1 className='nf__header'>Страница не найдена.</h1>
        <p className='nf__text'>
          Чтобы тоже самое не случилось с вашим автомобилем, подключите
          <span className='accent-text'> Labyrinth</span>.
        </p>
        <a
          className='nf__link'
          data-testid='return-link'
          href='https://business.labyrinth.pro/'
        >
          Вернуться
        </a>
      </div>
    </div>
  );
};

export default NotFound;
