import React, { Fragment, useEffect } from 'react';
import { ReactComponent as CloseIcon } from '../../../assets/cross_icon.svg';
import './forgotPassword.scss';
import { connect } from 'react-redux';
import {
  dropPassword,
  clearNotifications,
  clearErrors,
} from '../../../actions/authActions';
import { useForm } from 'react-hook-form';

const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const ForgotPassword = ({
  setModalOpen,
  dropPassword,
  clearErrors,
  auth: { error, notification },
}) => {
  const { register, errors, handleSubmit, setError } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: '',
    },
  });

  useEffect(() => {
    if (error && error === 'There is no user with that email') {
      setError('email', {
        type: 'manual',
        message: 'Такой адрес электронной почты не зарегестрирован.',
      });
      clearErrors();
    }
    // eslint-disable-next-line
  }, [error]);

  const submitForm = (data) => {
    dropPassword(data);
  };

  const onClose = (e) => {
    e.preventDefault();
    setModalOpen(false);
  };

  return (
    <Fragment>
      <div className='fp__background' onClick={onClose}></div>

      <div className='fp__container'>
        <form onSubmit={handleSubmit(submitForm)} className='fp__form'>
          <div className='fp__title'>
            <h2>Забыли пароль?</h2>
            <button onClick={onClose}>
              <CloseIcon />
            </button>
          </div>
          <p className='fp__text'>
            Введите адрес электронной почты на который отправить письмо с
            инструкцией по восстановлению пароля.
          </p>
          <input
            className='fp__input'
            type='text'
            name='email'
            placeholder='Email'
            ref={register({
              required: 'Введите адрес электронной почты.',
              pattern: {
                value: emailPattern,
                message: 'Неверный формат адреса электронной почты.',
              },
            })}
          />
          <small className='fp__error'>
            {errors?.email && errors.email.message}
          </small>
          <input className='fp__submit' type='submit' value='Отправить' />
        </form>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  dropPassword,
  clearNotifications,
  clearErrors,
})(ForgotPassword);
