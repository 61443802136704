import React from 'react';
import ReactDadataBox from 'react-dadata-box';
import { ReactComponent as ClearIcon } from '../../../assets/clear_icon.svg';
import './daDataInput.scss';

const DaDataInput = (props) => {
  const onChange = (res) => {
    props.onChange(res.value);
  };

  const clear = (e) => {
    e.preventDefault();
    props.onChange('');
  };

  return (
    <div className='dadata-field__container'>
      <div className={`dadata-field dadata-field--${props.error && 'error'}`}>
        <ReactDadataBox
          className='dadata-field__input'
          token='8c32d3120c06e6873ea086459274179728217fff'
          type={props.type}
          placeholder={props.title}
          name={props.name}
          onChange={onChange}
          query={props.value}
        />
        {props.value.length > 0 && (
          <button className='dadata-field__clear-btn' onClick={clear}>
            <ClearIcon />
          </button>
        )}
      </div>
      {props.error && props.error.message && (
        <small className='dadata-field__error'>{props.error.message}</small>
      )}
    </div>
  );
};

export default DaDataInput;
