import React, { Fragment } from 'react';
import { Redirect, Switch } from 'react-router-dom';
import Drawer from '../../../layouts/Drawer/Drawer/Drawer';
import DrawerContainer from '../../../layouts/Drawer/DrawerContainer/DrawerContainer';
import DrawerNavLink from '../../../layouts/Drawer/DrawerNavLink/DrawerNavLink';
import Header from '../../../layouts/Header/Header';
import LessorRoute from '../../../routing/LessorRoute';
import LessorNewOrder from '../LessorNewOrder/LessorNewOrder';
import { ReactComponent as NewOrderIcon } from '../../../../assets/add-order_icon.svg';
import { ReactComponent as TeamIcon } from '../../../../assets/team_icon.svg';
import { ReactComponent as StatisticIcon } from '../../../../assets/statistic_icon.svg';
import { ReactComponent as MapIcon } from '../../../../assets/map_icon.svg';
import { ReactComponent as ExternalLinkIcon } from '../../../../assets/external-link_icon.svg';
import LessorStatistic from '../LessorStatistic/LessorStatistic';
import LessorTeam from '../LessorTeam/LessorTeam';
import './LessorDashboard.scss';
import { connect } from 'react-redux';

const LessorDashboard = ({ auth: { user } }) => {
  return (
    <Fragment>
      <Header />
      <DrawerContainer>
        <Drawer title={user?.company?.companyName || 'Компания'}>
          <DrawerNavLink
            to='/leasing-dashboard/new-order/'
            name={'Новая заявка'}
            icon={NewOrderIcon}
          />
          <DrawerNavLink
            to='/leasing-dashboard/statistic/'
            name={'Статистика'}
            icon={StatisticIcon}
          />
          {user && (user.role === 'headOfLeasing' || user.role === 'admin') && (
            <DrawerNavLink
              to='/leasing-dashboard/team/'
              name={'Команда'}
              icon={TeamIcon}
            />
          )}
          <DrawerNavLink
            to='https://lk.labyrinth.pro/site/login'
            name={'Мониторинг'}
            external
            icon={MapIcon}
            tailIcon={ExternalLinkIcon}
          />
        </Drawer>
        <div className='content-container'>
          <Switch>
            <LessorRoute
              path='/leasing-dashboard/new-order/'
              component={LessorNewOrder}
            />
            <LessorRoute
              path='/leasing-dashboard/statistic/'
              component={LessorStatistic}
            />
            <LessorRoute
              path='/leasing-dashboard/team/'
              component={LessorTeam}
            />
            <Redirect
              from='/leasing-dashboard'
              to='/leasing-dashboard/statistic'
            />
          </Switch>
        </div>
      </DrawerContainer>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, null)(LessorDashboard);
