import React from 'react';
import { ReactComponent as AcceptedStatusIcon } from '../../../../../assets/accepted-status_icon.svg';
import { ReactComponent as DoneStatusIcon } from '../../../../../assets/done-status_icon.svg';
import { ReactComponent as DenideStatusIcon } from '../../../../../assets/denied-status_icon.svg';
import { ReactComponent as PreparatonStatusIcon } from '../../../../../assets/preparation_icon.svg';
import moment from 'moment';

const LessorStatisticTableRow = ({ data, onClickRow }) => {
  const acceptedStatus = (
    <div className='statistic-table__status'>
      <span className='statistic-table__status-icon'>
        <AcceptedStatusIcon />
      </span>
      <span className='statistic-table__status-label'>Принята</span>
    </div>
  );
  const doneStatus = (
    <div className='statistic-table__status'>
      <span className='statistic-table__status-icon'>
        <DoneStatusIcon />
      </span>
      <span className='statistic-table__status-label'>Выполнена</span>
    </div>
  );
  const deniedStatus = (
    <div className='statistic-table__status'>
      <span className='statistic-table__status-icon'>
        <DenideStatusIcon />
      </span>
      <span className='statistic-table__status-label'>Отменена</span>
    </div>
  );
  const preparationStatus = (
    <div className='statistic-table__status'>
      <span className='statistic-table__status-icon'>
        <PreparatonStatusIcon />
      </span>
      <span className='statistic-table__status-label'>В обработке</span>
    </div>
  );
  const statuses = {
    0: acceptedStatus,
    1: preparationStatus,
    2: doneStatus,
    3: deniedStatus,
  };

  const onClick = () => {
    onClickRow(data.id);
  };

  return (
    <tr className='statistic-table__row' onClick={onClick}>
      <td className='statistic-table__data'>{data.leasingAgreement}</td>
      <td className='statistic-table__data'>{data.installationAddress}</td>
      <td className='statistic-table__data'>{data.makeModel}</td>
      <td className='statistic-table__data'>
        {data.closedAt ? moment(data.closedAt).format('DD.MM.YYYY') : '-'}
      </td>
      <td className='statistic-table__data'>{statuses[data.status]}</td>
    </tr>
  );
};

export default LessorStatisticTableRow;
