import React, { useState, useEffect } from 'react';
import { ReactComponent as SearchIcon } from '../../../assets/search_icon.svg';
import './SearchRow.scss';

const SearchRow = ({
  placeholder = 'поиск и фильтрация',
  requestSearch = null,
}) => {
  const [query, setQuery] = useState('');

  useEffect(() => {
    if (requestSearch) {
      requestSearch(query);
    }
    // eslint-disable-next-line
  }, [query]);

  const updateQuery = (e) => {
    setQuery(e.target.value);
  };

  return (
    <div className='search-row'>
      <span className='search-row__icon'>
        <SearchIcon />
      </span>
      <input
        type='text'
        name='search'
        className='search-row__input'
        value={query}
        placeholder={placeholder}
        onChange={updateQuery}
      />
    </div>
  );
};

export default SearchRow;
